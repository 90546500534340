import { cleanMaskNum, cleanMaskNumAlpha, ORGANIZATION_BIK_MASK, ORGANIZATION_CHECK_ACC_NUMBER_MASK, ORGANIZATION_CORR_ACC_NUMBER_MASK, ORGANIZATION_INN_MASK, ORGANIZATION_KPP_MASK, ORGANIZATION_OKPO_MASK } from 'common/Mask/Organization'
import { Meta } from 'entity/Meta'
import { CustomerForUpdate } from 'api/customer'
import { phoneClean, PHONE_MASK } from 'common/Mask/PhoneMask'

const customer = new Meta<CustomerForUpdate>(
  {
    phone: { title: 'Номер телефона', mask: PHONE_MASK, cleanMask: phoneClean },
    inn: { title: 'ИНН', mask: ORGANIZATION_INN_MASK, cleanMask: cleanMaskNum },
    okpo: { title: '​ОКПО', mask: ORGANIZATION_OKPO_MASK, cleanMask: cleanMaskNum },
    fullName: { title: '​Название' },
    correspondentAccountNumber: { title: 'Номер корреспондентского счета', mask: ORGANIZATION_CORR_ACC_NUMBER_MASK, cleanMask: cleanMaskNum },
    kpp: { title: 'КПП', mask: ORGANIZATION_KPP_MASK, cleanMask: cleanMaskNumAlpha },
    checkingAccountNumber: { title: 'Номер расчетного счета', mask: ORGANIZATION_CHECK_ACC_NUMBER_MASK, cleanMask: cleanMaskNum },
    bik: { title: 'БИК', mask: ORGANIZATION_BIK_MASK, cleanMask: cleanMaskNum },
    bankName: { title: '​Наименование банка' },
    organizationHeadName: { title: 'ФИО руководителя организации' },
    legalAddress: { title: 'Юридический адрес' }
  }
)

export default customer
