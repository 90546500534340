import TsToFormatDate from 'util/TsToFormatDate'
import { now } from 'util/date'
import { mediaGenerateXLSX, XLSXTableParams } from 'api/media'

export default async function downloadXLSXFile (convertedData:XLSXTableParams, filename: string): Promise<void> {
  try {
    const blob = await mediaGenerateXLSX(convertedData)
    const element = document.createElement('a')
    element.href = URL.createObjectURL(blob)
    element.download = `${filename}_${TsToFormatDate(now(), 'ddMMyyyy')}.xlsx`
    element.click()
  } catch (error) {
    console.error('Error generating XLSX file:', error)
  }
}
