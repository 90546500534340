import { AddressForCreate } from 'api/address'
import { idPattern } from 'api/Id'
import { makeJsonSchema, SimpleSchema } from './validate'
import { SCALE_LENGTH_MAX, SCALE_LENGTH_MIN, WEIGHING_SCALE_MAX_LOAD, WEIGHING_SCALE_MIN_LOAD } from 'util/checkWeighingValue'

type Address = Omit<AddressForCreate, 'schedule'>

const simpleSchema: SimpleSchema<Address> = {
  type: 'object',
  properties: {
    // type: { type: 'string', enum: Object.values(AddressType) },
    address: { type: 'string', pattern: '^[а-яА-ЯёЁ0-9,.()\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнен адрес' },
    coordinates: {
      type: 'object',
      properties: {
        lat: { type: 'number' },
        lon: { type: 'number' }
      }
    },
    region: { type: 'string', pattern: '^[а-яА-ЯёЁ0-9,.()\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена область', optional: true },
    city: { type: 'string', pattern: '^[а-яА-ЯёЁ0-9,.()\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнен населенный пункт', optional: true },

    name: { type: 'string', minLength: 1, errorMessage: 'Некорректно заполнено название пункта', optional: true },
    weighingScaleLength: { type: 'number', minimum: SCALE_LENGTH_MIN, maximum: SCALE_LENGTH_MAX, optional: true },
    weighingScaleMaxLoad: { type: 'number', minimum: WEIGHING_SCALE_MIN_LOAD, maximum: WEIGHING_SCALE_MAX_LOAD, optional: true },
    district: { type: 'string', pattern: '^[а-яА-ЯёЁ0-9,.()\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнен район', optional: true },
    street: { type: 'string', pattern: '^[а-яА-ЯёЁ0-9,.()\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена улица', optional: true },
    building: { type: 'string', optional: true },
    externalId: { type: 'string', optional: true },
    comment: { type: 'string', optional: true },
    isPart: { type: 'boolean', optional: true },
    consignorConsigneeIds: { type: 'array', items: { type: 'string', pattern: idPattern }, optional: true }
  }
}

const schema = makeJsonSchema<Address>(simpleSchema)

export default schema
