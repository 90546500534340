import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { CarrierContractWizardType } from './index'
import { Typography } from '@mui/material'
import { useEffect, useRef } from 'react'

export default function Info ({ isActive, data, handleNext }: StepProps) {
  const once = useRef(true)
  const d = data as CarrierContractWizardType

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    if (d.signing) {
      handleNext()
    }
  }, [isActive, d.signing, handleNext])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography>Для того чтобы откликнуться на данную заявку, вам необходимо заключить договор с {d.customer.fullName}</Typography>
  </>)
}
