import { ProfileContractCreate } from 'api/profile'
import { makeJsonSchema, SimpleSchema } from './validate'

const simpleSchema: SimpleSchema<ProfileContractCreate> = {
  type: 'object',
  properties: {
    customerId: { type: 'string' },
    num: { type: 'string' },
    createTs: { type: 'number' },

    filename: { type: 'string', optional: true }
  }
}

const schema = makeJsonSchema<ProfileContractCreate>(simpleSchema)

export default schema
