import { Box, Button, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { ShippingTruckRunExtBid, shippingGet, shippingAllGet, ShippingTruckRunStatus, shippingRefuse, isArchiveStatus, ShippingTruckRunCarrierComment, KisRequestHistory } from 'api/shipping'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import './styles.sass'
import { Id, isId } from 'api/Id'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import InfoCard, { InfoItemParams } from 'ui/InfoCard'
import Map from 'ui/Osm'
import TsToFormatDate from 'util/TsToFormatDate'
import rub from 'util/formatRub'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import { useAuthContext } from 'AuthContext'
import { Interface } from 'api/Interface'
import FullAddress from 'ui/FullAddress'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import ChipStatus from 'ui/ChipStatus'
import formatPhone from 'util/formatPhone'
import formateParties from 'util/formateParties'
import Alert from 'ui/Alert'
import formatCargo from 'util/formatCargo'
import CompleteModal from 'pages/CarrierTrip/CompleteModal'
import download from 'util/download'
import formatScaleLength from 'util/formatScaleLength'
import formatScaleMaxLoad from 'util/formatScaleMaxLoad'
import { toTon } from 'util/weight'
import { toKilometers } from 'util/distance'
import { Act } from 'api/act'
import CollapseList from 'ui/CollapseList'
import SquareButton from 'ui/SquareButton'
import { AddComment, Close, Edit, QrCode2, Visibility } from '@mui/icons-material'
import { getChipsByStatus as getChipsByStatusDisp } from 'pages/DispatcherActs'
import { getChipsByStatus } from 'pages/CarrierActs'
import TruckRunEditModel from 'ui/TruckRunEditModel'
import SentToKisStatus from 'ui/SentToKisStatus'
import formatWorkSchedule from 'util/formatWorkSchedule'
import formatHeightRestriction from 'util/formatHeightRestriction'
import InfoIcon from '@mui/icons-material/Info'
import AstonTrafficReserveSlotModal from './AstonTrafficReserveSlotModal'
import QRCodeModel from './QrcodeModel'
import AstonTrafficReserveCancelModal from './AstonTrafficReserveCancelModal'
import { AstonTrafficReservedSlot } from 'api/aston-traffic'
import CarrierCommentModal from 'ui/CarrierCommentModal'
import None from 'ui/None'
import { BidCargo, bidCargoList } from 'api/bid'
import KisRequestHistoryWizard from 'ui/KisRequestHistoryWizard'

const getInfo = (data: ShippingTruckRunExtBid) => {
  const result: InfoItemParams[] = []
  result.push({ label: 'Статус', value: data.status === ShippingTruckRunStatus.confirmed ? <ChipStatus label='Запланирован' /> : ChipTruckRunStatus(data.status) })

  !!data.sentToKisStatus && result.push({ label: 'Cтатус передачи данных в КИС:', value: <SentToKisStatus status={data.sentToKisStatus} /> })

  result.push(
    { label: 'Тип ТС:', value: data.bid.typeVehicle.length === 0 ? 'Любой' : data.bid.typeVehicle.map(item => item.name).join(', ') },
    { label: 'Дата погрузки:', value: TsToFormatDate(data.actualLoadingTs ?? data.loadingTs, 'dd MMMM yyyy') },
    { label: 'Общий вес перевозки, тн:', value: `${toTon(data.bid.totalWeight)} тн` },
    { label: 'Стоимость груза за 1 тн:', value: rub(data.bid.costCargo) },
    { label: 'Груз:', value: formatCargo(data.bid.cargo) },
    { label: 'Расстояние:', value: `${toKilometers(data.bid.distance)} км` },
    { label: 'Стоимость перевозки тн/км:', value: rub(data.bid.costTonKm) },
    { label: 'Cтоимость перевозки 1 тн:', value: rub(data.bid.costTon) }
  )

  !!data.billTs && result.push({ label: 'Дата ТрН/ТТН', value: TsToFormatDate(data.billTs, 'dd MMMM yyyy') })
  !!data.billNumber && result.push({ label: 'Номер Трн/ТТН:', value: data.billNumber })
  !!data.weight && result.push({ label: 'Вес погрузки:', value: `${toTon(data.weight)} т.` })
  !!data.costWeight && result.push({ label: 'Стоимость веса погрузки:', value: rub(data.costWeight) })
  !!data.unloadingTs && result.push({ label: 'Дата выгрузки:', value: TsToFormatDate(data.unloadingTs, 'dd MMMM yyyy') })
  !!data.finalWeight && result.push({ label: 'Вес разгрузки:', value: `${toTon(data.finalWeight)} т.` })
  !!data.cost && result.push({ label: 'Стоимость веса разгрузки:', value: rub(data.cost) })

  result.push(
    { label: 'Заказчик перевозки:', value: data.bid.customer.fullName },
    { label: 'Грузим +10%:', value: data.bid.plusTen ? 'Да' : 'Нет' },
    { label: 'ТС:', value: `${vehicleName(data.vehicle)} ${data.vehicle.color}` },
    { label: 'Прицеп:', value: trailerName(data.trailer, { color: true }) },
    { label: 'Водитель:', value: personName(data.driver) },
    { label: 'Номер телефона:', value: formatPhone(data.driver.phone) }
  )

  return result
}

export default function CarrierTrip () {
  const { currentInterface } = useAuthContext()
  const [data, setData] = useState<ShippingTruckRunExtBid>()
  const [found, setFound] = useState<boolean>()
  const [completeModalId, setCompleteModalId] = useState<Id>()
  const params = useParams()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()
  const isAdmin = useMemo(() => currentInterface ? [Interface.administrator, Interface.chief_dispatcher, Interface.dispatcher, Interface.forwarder].includes(currentInterface) : false, [currentInterface])
  const [scanBillMenu, setScanBillMenu] = useState<null | HTMLElement>(null)
  const [acts, setActs] = useState<Act[]>()
  const [editId, setEditId] = useState<Id>()
  const [comments, setComments] = useState<ShippingTruckRunCarrierComment[]>()
  const [reserveSlot, setReserveSlot] = useState<ShippingTruckRunExtBid>()
  const [viewQrcode, setViewQrcode] = useState<ShippingTruckRunExtBid>()
  const [reserveCancel, setReserveCancel] = useState<ShippingTruckRunExtBid>()
  const [refuseConflict, setRefuseConflict] = useState<AstonTrafficReservedSlot>()
  const [openCommentModal, setOpenCommentModal] = useState<ShippingTruckRunExtBid>()
  const [cargoList, setCargoList] = useState<BidCargo[]>([])
  const [kisRequestHistory, setKisRequestHistory] = useState<KisRequestHistory[]>()

  const apiShippingGet = isAdmin ? shippingAllGet : shippingGet

  const isShipping = data?.status !== ShippingTruckRunStatus.new
  const isChief = currentInterface === Interface.chief_dispatcher
  const isCarrier = currentInterface === Interface.carrier
  const isEdit = isChief && data?.status ? isShipping && (!isArchiveStatus(data.status) || data.status === ShippingTruckRunStatus.archiveOutdated) : false
  const viewComment = ((comments !== undefined && comments.length > 0) || (isCarrier && data?.status === ShippingTruckRunStatus.way))

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.REGISTRY_TRUCK_RUNS_PAGE),
    [links, routesMap]
  )

  const title = useMemo(
    () => `Рейс ${data?.num} по перевозке ${data?.bid.num} от ${TsToFormatDate(data?.loadingTs, 'dd.MM')}`,
    [data]
  )

  const init = useCallback((refresh: boolean = false) => {
    if (found !== undefined && !refresh) {
      return
    }

    if (!isId(params.id)) {
      setFound(false)
      return
    }

    if (isAdmin) {
      bidCargoList().then(setCargoList)
    }

    apiShippingGet(params.id).then(result => {
      if (result === null) {
        setFound(false)
      } else {
        const { acts, carrierComments, kisRequestHistory, ...d } = result
        setData(d)
        setActs(acts)
        setComments(carrierComments)
        setFound(true)
        setKisRequestHistory(kisRequestHistory)
      }
    })
  }, [found, params.id, apiShippingGet, isAdmin])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const handleBack = () => {
    navigate(-1)
  }

  const handleRefuse = async () => {
    if (!isId(params.id)) {
      return
    }

    const { success, conflicts } = await shippingRefuse(params.id)

    if (!success) {
      if (conflicts && conflicts.reservedSlot) {
        setReserveCancel(data)
        setRefuseConflict(conflicts.reservedSlot)
      }

      return
    }

    handleBack()
  }

  const getRoutesItems = (data: ShippingTruckRunExtBid) => {
    const items = [
      { label: 'Место погрузки:', value: <FullAddress address={data.bid.loadingAddress} /> },
      {
        label: 'Место разгрузки:',
        value: data.unloadingAddress
          ? <>
            <Box><FullAddress address={data.unloadingAddress} /></Box>
            <Box sx={{ color: '#B2B2B2', mt: 1 }}><FullAddress address={data.bid.unloadingAddress} /></Box>
          </>
          : <FullAddress address={data.bid.unloadingAddress} />
      }
    ]

    if (data.reservedUnloadingSlot) {
      const { from, to } = data.reservedUnloadingSlot

      items.push({
        label: 'Дата и время разгрузки:',
        value: <>{TsToFormatDate(from, 'dd.MM.yyyy')} | {TsToFormatDate(from, 'HH:mm')} — {TsToFormatDate(to, 'HH:mm')}</>
      })
    }
    return items
  }

  if (found === undefined) {
    return <></>
  }

  if (!found) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (
    <>
      <CompleteModal
        id={completeModalId}
        onClose={() => setCompleteModalId(undefined)}
        onDone={() => {
          setCompleteModalId(undefined)
          navigate(-1)
        }}
      />
      <AstonTrafficReserveSlotModal
        truckRun={reserveSlot}
        onClose={() => setReserveSlot(undefined)}
        onReserved={() => {
          setReserveSlot(undefined)
          init(true)
        }}
      />
      <QRCodeModel truckRun={viewQrcode} onClose={() => setViewQrcode(undefined)}/>
      <AstonTrafficReserveCancelModal
        truckRun={reserveCancel}
        conflictSlot={refuseConflict}
        onClose={() => {
          setReserveCancel(undefined)
          setRefuseConflict(undefined)
        }}
        onCancel={() => {
          if (refuseConflict) {
            handleRefuse()
          }

          setReserveCancel(undefined)
          setRefuseConflict(undefined)
          init(true)
        }}
      />
      <CarrierCommentModal
        data={openCommentModal}
        onClose={() => { setOpenCommentModal(undefined) }}
        onSuccess={() => {
          init(true)
          setOpenCommentModal(undefined)
        }}
      />
      <div className='carrierTrip'>
        <div className='carrierTrip_content'>
          <NavigatePanel
            breadcrumbs={{
              defaultItems: breadcrumbsItems,
              items: [{ title }]
            }}
            title={title}
            actions={<Stack direction='row' justifyContent='flex-end'>
              { isEdit && <IconButton onClick={() => setEditId(data?.id)}>
                <Edit />
              </IconButton> }
              <IconButton onClick={() => handleBack()}>
                <Close />
              </IconButton>
            </Stack>}
          />
          <div className='carrierTrip_content_info'>
            { data && <>
                { data.status === ShippingTruckRunStatus.archiveProblem && <Alert color='#EE6A5F' sx={{ margin: '1em 1.5em' }} >
                <>
                  <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Завершен диспетчером</Typography>
                  <Typography fontWeight={400} fontSize='13px' color='#ffffff'>Причина: {data.problemComment}</Typography>
                </>
              </Alert> }
                { data.unloadingAddress && <Alert color='#ffa800' sx={{ margin: '1em 1.5em' }} >
                <>
                  <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Рейс был переадресован</Typography>
                </>
              </Alert> }
              <InfoCard className='carrierTrip_content_infoCard'
                title={<Stack direction='row' alignItems='center'>
                  <Box>Маршрут</Box>
                  { data.unloadingAddress && <Box sx={{ color: '#ffa800', mt: 0.5, ml: 1 }}><InfoIcon /></Box> }
                </Stack>}
                items={getRoutesItems(data)}
                actions={<>
                  { [ShippingTruckRunStatus.way]
                    .includes(data.status) && <>
                      { !data.reservedUnloadingSlot
                        ? <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        onClick={() => setReserveSlot(data)}
                      >Выбрать дату и время разгрузки</Button>
                        : <Stack direction='row' gap={1}>
                          <Button
                            variant='contained'
                            color='error'
                            onClick={() => setReserveCancel(data)}
                            size='small'
                          >Отменить</Button>
                          <SquareButton
                            variant='contained'
                            color='primary'
                            onClick={() => setViewQrcode(data)}
                          ><QrCode2 /></SquareButton>
                      </Stack>
                    }
                      </> }
                </>}
              />
              { isAdmin && kisRequestHistory && kisRequestHistory.length > 0 && <InfoCard className='carrierTrip_content_infoCard'
                title='История запросов КИС'
              >
                <CollapseList
                  list={kisRequestHistory.sort((a, b) => b.requestTs - a.requestTs)}
                  previewItems={3}
                  element={data => <KisRequestHistoryWizard data={data} />}
                />
              </InfoCard> }
              { viewComment && <InfoCard className='carrierTrip_content_infoCard'
                title='Комментарии перевозчика'
                actions={<>
                { isCarrier && data.status === ShippingTruckRunStatus.way && <Button
                    variant='contained'
                    size='small'
                    color='secondary'
                    sx={{ ml: '10px', color: '#475569' }}
                    endIcon={<AddComment />}
                    onClick={() => { setOpenCommentModal(data) }}
                  >Добавить комментарий</Button>}
                </>}
              ><>
                { comments !== undefined && comments.length > 0
                  ? <CollapseList
                    list={(comments ?? []).sort((a, b) => b.createTs - a.createTs)}
                    previewItems={3}
                    element={({ createTs, comment }) => {
                      return (<Stack direction='row' alignItems='center' spacing={1}>
                        <Typography fontSize='13px' fontWeight={600} >{TsToFormatDate(createTs, 'dd.MM.yyyy HH:mm')}</Typography>
                        <Typography fontSize='13px' fontWeight={500}>{comment}</Typography>
                      </Stack>)
                    }}
                  />
                  : <None desc='Нажмите кнопку "Добавить комментарий"' />}
                </>
              </InfoCard> }
              { acts !== undefined && <InfoCard className='carrierTrip_content_infoCard'
                title='Пакеты документов'
              >
                <CollapseList
                  list={acts}
                  previewItems={3}
                  element={({ num, createTs, id: actId, status: actStatus }) => {
                    return (<Stack direction='row' alignItems='center' spacing={1}>
                      <Typography fontSize='13px' fontWeight={500}>УПД №{num} от {TsToFormatDate(createTs, 'dd.MM.yyyy HH:mm')}</Typography>
                      <SquareButton
                        size='small'
                        variant='outlined'
                        color='secondary'
                        onClick={() => navigate(`${isAdmin ? links.DISPATCHER_DOCUMENTS_ACT_PAGE : links.CARRIER_DOCUMENTS_ACT_PAGE}/${actId}`)}
                      >
                        <Visibility sx={{ color: '#EBEBEB' }} />
                      </SquareButton>
                      { isAdmin ? getChipsByStatusDisp(actStatus) : getChipsByStatus(actStatus)}
                    </Stack>)
                  }}
                />
              </InfoCard> }
              <InfoCard className='carrierTrip_content_infoCard'
                title='Информация о рейсе'
                items={getInfo(data)}
                actions={<Stack gap={1}>
                  { data.status === ShippingTruckRunStatus.arrived && (!data.unloadingAddress || data.consignee) && !isAdmin && <Button
                    variant='contained'
                    onClick={() => setCompleteModalId(data.id)}
                    startIcon={<NoteAddIcon />}
                  >Загрузить фото ТрН/ТТН</Button> }
                  { data.scanBills && data.scanBills.length > 0 && <>
                    <Button
                      variant='contained'
                      onClick={({ currentTarget }) => {
                        if (!data.scanBills || data.scanBills.length === 0) {
                          return
                        }

                        if (data.scanBills.length === 1) {
                          download(data.scanBills[0])
                        } else {
                          setScanBillMenu(currentTarget)
                        }
                      }}
                    >Фото Транспортной накладной</Button>
                    {data.scanBills.length > 1 && <Menu
                      anchorEl={scanBillMenu}
                      open={!!scanBillMenu}
                      onClose={ () => setScanBillMenu(null) }
                    >
                      {data.scanBills.map((scanBill, idx) =>
                        <MenuItem key={idx} onClick={() => download(scanBill)}>Фото {idx + 1}</MenuItem>
                      )}
                    </Menu>}
                  </>}
                  { !isAdmin && data.status === ShippingTruckRunStatus.confirmed && <Button
                    variant='outlined'
                    size='small'
                    color='secondary'
                    sx={{ ml: '10px', color: '#475569' }}
                    onClick={handleRefuse}
                  >Отозвать отклик</Button>}
                </Stack>}
              />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте погрузки'
                items={[
                  { label: 'Режим работы:', value: formatWorkSchedule(data.bid.loadingAddress.schedule) },
                  { label: 'Способ погрузки:', value: data.bid.loadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.loadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.loadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.bid.loadingWeighing) },
                  { label: 'Грузоотправитель:', value: formateParties(data.bid.consignor) }
                ]} />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте разгрузки'
                items={[
                  { label: 'Режим работы:', value: formatWorkSchedule((data.unloadingAddress ?? data.bid.unloadingAddress).schedule) },
                  { label: 'Способ разгрузки:', value: data.bid.unloadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.unloadingWeighing) },
                  { label: 'Высота ТС:', value: formatHeightRestriction(data.bid.unloadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.unloadingWeighing) },
                  { label: 'Грузополучатель:', value: formateParties(data.consignee ?? data.bid.consignee) }
                ]}
              />
              { data.forwarder && <InfoCard className='carrierTrip_content_infoCard'
                title='Экспедитор'
                items={[
                  { label: 'ФИО:', value: personName(data.forwarder) },
                  { label: 'Номер телефона:', value: formatPhone(data.forwarder.phone) }
                ]}
              /> }
              { !data.forwarder && data.forwarders && data.forwarders.length > 0 && <InfoCard className='carrierTrip_content_infoCard'
                title='Экспедиторы'
                items={data.forwarders.map(item => ({ label: personName(item), value: formatPhone(item.phone) }))}
              /> }
            </>}
          </div>
        </div>
        <div className='carrierTrip_map'>
        { data && <Map route={{ from: data.bid.loadingAddress.coordinates, to: (data.unloadingAddress ?? data.bid.unloadingAddress).coordinates }} /> }
        </div>
      </div>
      <TruckRunEditModel
        cargoList={cargoList}
        id={editId}
        onClose={() => setEditId(undefined)}
        onSave={() => {
          init(true)
          setEditId(undefined)
        }}
      />
    </>
  )
}
