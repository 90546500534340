import { mainService } from 'api/service'
import { Id } from 'api/Id'
import { Organization } from './organization'
import { Bid } from './bid'

export enum CustomerStatus {
  active = 'active',
  archive = 'archive',
}

export type CustomerIncomplete = Partial<{
  innEmpty: boolean
  kppEmpty: boolean
  okpoEmpty: boolean
  fullNameEmpty: boolean
  externalTypeEmpty: boolean
  legalAddressEmpty: boolean
  phonesEmpty: boolean
  checkingAccountNumberEmpty: boolean
  bankNameEmpty: boolean
  bikEmpty: boolean
  correspondentAccountNumberEmpty: boolean
  boxIdEmpty?: boolean
  diadocUserEmpty?: boolean
  diadocPasswordEmpty?: boolean
}>

export type Customer = Required<Pick<Organization, 'inn' | 'okpo' | 'fullName'
  | 'bankName' | 'correspondentAccountNumber'
  | 'checkingAccountNumber' | 'bik' | 'kpp'
  | 'organizationHeadName' | 'legalAddress'>
  > & {
  id: Id
  status: CustomerStatus
  phone: string

  archiveDate?: number
  archiveComment?: string

  externalName?: string
  externalType?: string
  externalId?: string
  externalPhones?: string
  externalEmails?: string

  diadocBoxId?: string

  diadocUser?: string
  diadocPassword?: string

  incomplete?: CustomerIncomplete
  hasDiadocPassword?: boolean
}

type ModifyOmited = 'status' | 'archiveDate' | 'archiveComment' | 'externalId' |
  'externalName' | 'externalType' | 'externalPhones' | 'externalEmails' | 'incomplete' |
  'hasDiadocPassword'
export type CustomerForUpdate = Partial<Omit<Customer, ModifyOmited>>
export type CustomerForCreate = Omit<CustomerForUpdate, 'id' | ModifyOmited>

export interface CustomerArchiveParams {
  id: Id
  date: number
  comment?: string
}

export interface CustomerListParams {
  filters?: {
    status?: CustomerStatus
  },
  includeCompleteness?: boolean
}

export interface CustomerDispatcherListParams {
  filters?: {
    onlyComplete?: boolean
  }
}

const request = (method: string, params: object) => mainService.request(`customer.${method}`, params)

export const customerCreate = (params: CustomerForCreate) => request('create', params) as Promise<Id | null>
export const customerUpdate = (params: CustomerForUpdate) => request('update', params) as Promise<boolean>
export const customerGet = (id: Id) => request('get', { id }) as Promise<Customer | null>
export const customerList = (params: CustomerListParams = {}) => request('list', params) as Promise<Customer[]>
export const customerDelete = (id: Id) => request('delete', { id }) as Promise<boolean>
export const customerDispatcherList = (params: CustomerDispatcherListParams = {}) => request('dispatcher.list', params) as Promise<Customer[]>
export const customerArchive = (params: CustomerArchiveParams) => request('archive', params) as Promise<{
  success: boolean
  conflicts?: Bid[]
}>
