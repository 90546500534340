import { AlertColor } from '@mui/material'
import { SSIEventType } from 'EventContext'
import { SnackParams, useEnqueueSnackbar } from 'util/useEnqueueSnackbar'
import TruckRunNewResponse from './TruckRunNewResponse'
import TruckRunReject from './TruckRunReject'
import TruckRunConfirmed from './TruckRunConfirmed'
import DocumentsWaitingSigning from './DocumentsWaitingSigning'
import DocumentSsignedByCustomer from './DocumentSsignedByCustomer'
import DocumentRefused from './DocumentRefused'
import DocumentCanceled from './DocumentCanceled'
import DriverAdd from './DriverAdd'
import DriverBlocked from './DriverBlocked'
import DriverConfirmed from './DriverConfirmed'
import DriverUpdate from './DriverUpdate'
import DriverArhiveCarrier from './DriverArhiveCarrier'
import DriverDispatcherArhive from './DriverDispatcherArhive'
import TrailerAdd from './TrailerAdd'
import TrailerArhiveCarrier from './TrailerArhiveCarrier'
import TrailerDispatcherArhive from './TrailerDispatcherArhive'
import TrailerUpdate from './TrailerUpdate'
import VehicleAdd from './VehicleAdd'
import VehicleArhiveCarrier from './VehicleArhiveCarrier'
import VehicleBlocked from './VehicleBlocked'
import VehicleConfirmed from './VehicleConfirmed'
import VehicleDispatcherArhive from './VehicleDispatcherArhive'
import VehicleUpdate from './VehicleUpdate'
import CarrierNew from './CarrierNew'
import ForwarderNewLoad from './ForwarderNewLoad'
import TruckRunLoad from './TruckRunLoad'
import TruckRunRedirect from './TruckRunRedirect'
import ForwarderAttach from './ForwarderAttach'
import BidPublished from './BidPublished'
import DriverBlacklisted from './DriverBlacklisted'
import VehicleBlacklisted from './VehicleBlacklisted'
import PassportExpirationDate from './PassportExpirationDate'
import FocusSuccessResponse from './FocusSuccessResponse'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import FocusFailureResponse from './FocusFailureResponse'
import TruckRunLongToWay from './TruckRunLongToWay'
import TruckRunWeightCorrection from './TruckRunWeightCorrection'
import ProfileRequestAddDriversNew from './ProfileRequestAddDriversNew'
import ProfileRequestAddDriversCancel from './ProfileRequestAddDriversCancel'
import ProfileRequestAddDriversAccept from './ProfileRequestAddDriversAccept'
import TruckRunRefuse from './TruckRunRefuse'
import TruckRunArrived from './TruckRunArrived'
import TruckRunNewCarrierComment from './TruckRunNewCarrierComment'
import AstonTrafficSlotReservation from './AstonTrafficSlotReservation'
import AstonTrafficReservationCancel from './AstonTrafficReservationCancel'
import BidPublishedInRegion from './BidPublishedInRegion'
import TruckRunLongConfirmed from './TruckRunLongConfirmed'
import BidManualFill from './BidManualFill'

export type NotifyEvent = Partial<SSIEventType> & {
  toast?: (message: string | null | undefined, color?: AlertColor) => void
  snack?: (param: SnackParams) => void
  navigate?: NavigateFunction
}

export default function Notify ({ event }: { event?: SSIEventType }) {
  const { toast, snack } = useEnqueueSnackbar()
  const navigate = useNavigate()

  return (<>
    <TruckRunNewResponse {...event} toast={toast} />
    <TruckRunReject {...event} toast={toast} />
    <TruckRunRefuse {...event} snack={snack} navigate={navigate} />
    <TruckRunArrived {...event} toast={toast} />
    <TruckRunConfirmed {...event} toast={toast} />
    <DocumentsWaitingSigning {...event} toast={toast} />
    <DocumentSsignedByCustomer {...event} toast={toast} />
    <DocumentRefused {...event} toast={toast} />
    <DocumentCanceled {...event} toast={toast} />
    <DriverAdd {...event} toast={toast} />
    <DriverBlocked {...event} toast={toast} />
    <DriverConfirmed {...event} toast={toast} />
    <DriverUpdate {...event} toast={toast} />
    <DriverArhiveCarrier {...event} toast={toast} />
    <DriverDispatcherArhive {...event} toast={toast} />
    <TrailerAdd {...event} toast={toast} />
    <TrailerArhiveCarrier {...event} toast={toast} />
    <TrailerDispatcherArhive {...event} toast={toast} />
    <TrailerUpdate {...event} toast={toast} />
    <VehicleAdd {...event} toast={toast} />
    <VehicleArhiveCarrier {...event} toast={toast} />
    <VehicleBlocked {...event} toast={toast} />
    <VehicleConfirmed {...event} toast={toast} />
    <VehicleDispatcherArhive {...event} toast={toast} />
    <VehicleUpdate {...event} toast={toast} />
    <CarrierNew {...event} toast={toast} />
    <ForwarderNewLoad {...event} toast={toast} />
    <TruckRunLoad {...event} toast={toast} />
    <TruckRunRedirect {...event} toast={toast} />
    <ForwarderAttach {...event} toast={toast} />
    <BidPublished {...event} toast={toast} />
    <BidPublishedInRegion {...event} snack={snack} navigate={navigate}/>
    <BidManualFill {...event} toast={toast} />
    <DriverBlacklisted {...event} toast={toast} />
    <VehicleBlacklisted {...event} toast={toast} />
    <PassportExpirationDate {...event} toast={toast} />
    <FocusSuccessResponse {...event} snack={snack} navigate={navigate} />
    <FocusFailureResponse {...event} toast={toast} />
    <TruckRunLongToWay {...event} toast={toast} />
    <TruckRunLongConfirmed {...event} snack={snack} navigate={navigate}/>
    <TruckRunWeightCorrection {...event} toast={toast} />
    <ProfileRequestAddDriversNew {...event} toast={toast} />
    <ProfileRequestAddDriversCancel {...event} toast={toast} />
    <ProfileRequestAddDriversAccept {...event} toast={toast} />
    <TruckRunNewCarrierComment {...event} snack={snack} navigate={navigate} />
    <AstonTrafficSlotReservation {...event} toast={toast} />
    <AstonTrafficReservationCancel {...event} toast={toast} />
  </>)
}
