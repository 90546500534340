import { Box, Button, Typography } from '@mui/material'
import { useAuthContext } from 'AuthContext'
import './styles.sass'

export default function EndPage () {
  const { logout } = useAuthContext()
  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent='center' width='100%'>
        <Box maxWidth='500px'>
          <Typography fontWeight={700} fontSize='21px' mb='1em'>Спасибо за регистрацию!</Typography>
          <Typography fontWeight={400} fontSize='19px' color='#B2B2B2' mb='1.5em'>Вы успешно зарегистрировались в системе Астон. Войдите в систему для начала работы.</Typography>
          <Button size="large" variant="contained" fullWidth onClick={ () => { logout() } }>Войти в систему</Button>
        </Box>
      </Box>
    </>
  )
}
