import { useCallback, useEffect, useState } from 'react'
import './styles.sass'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import { Id, isId } from 'api/Id'
import NavigatePanel from 'ui/NavigatePanel'
import { Box, Button, ButtonGroup, IconButton, Stack, Typography } from '@mui/material'
import { ActStatus, actAllChangeStatus, actAllGet, checkChangedInDiadocStatus } from 'api/act'
import CloseIcon from '@mui/icons-material/Close'
import InfoCard from 'ui/InfoCard'
import rub from 'util/formatRub'
import TsToFormatDate from 'util/TsToFormatDate'
import SquareButton from 'ui/SquareButton'
import { ReportProblem, Visibility, Warning } from '@mui/icons-material'
import download from 'util/download'
import InfoCell from 'ui/InfoCell'
import clsx from 'clsx'
import ChipStatus from 'ui/ChipStatus'
import { useAuthContext } from 'AuthContext'
// import ActionsMenu from 'ui/ActionsMenu'
import ActDispatcherRejectionWizard from 'ui/ActDispatcherRejectionWizard'
import Alert from 'ui/Alert'
import TruckRunEditModal from './TruckRunEditModal'
import { toTon } from 'util/weight'
import { getChipsByStatus } from 'pages/DispatcherActs'

type Data = NonNullable<Awaited<ReturnType<typeof actAllGet>>>
export type TruckRuns = Data['truckRuns'][0]

interface TruckRunItemParams {
  data: TruckRuns
  onClick: () => void
  selected?: boolean
  disabled?: boolean
  hasError?: boolean
}

export const TruckRunStatus = <T extends {isChecked?: boolean, wasEdited?: boolean}>({ isChecked, wasEdited }: T) =>
  isChecked
    ? wasEdited
      ? <ChipStatus label='Проверен, изменен' color='red' />
      : <ChipStatus label='Проверен' color='green' />
    : <ChipStatus label='Ожидает проверки' />

const TruckRunItem = ({ data, onClick, selected, disabled, hasError }: TruckRunItemParams) => {
  return (<InfoCard
    className={clsx('truckRunItem', { truckRunItem_disabled: disabled })}
    onClick={!disabled ? onClick : undefined}
    style={{
      cursor: 'pointer',
      border: hasError ? '1px solid #EE6A5F' : selected ? '1px solid #624CE0' : undefined
    }}
  >
    <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
      <Stack direction='row' gap={1} >
        <InfoCell label='Номер рейса' value={data.num} className={clsx({ truckRun_selected: selected })} />
        <InfoCell label='Номер ТрН/ТТН' value={data.billNumber} className={clsx({ truckRun_selected: selected })} />
        <InfoCell label='Дата ТрН/ТТН' value={data.billTs ? TsToFormatDate(data.billTs, 'dd.MM.yyyy') : '-'} className={clsx({ truckRun_selected: selected })} />
        <InfoCell label='Дата рейса' value={TsToFormatDate(data.loadingTs, 'dd.MM.yyyy')} className={clsx({ truckRun_selected: selected })} />
        <InfoCell
          label='Вес погрузки'
          value={ data.weightCorrection && data.weightCorrection !== data.weight
            ? <>
              { toTon(data.weight ?? 0) }т <span style={{ color: '#EE6A5F' }}>({ toTon(data.weightCorrection ?? 0) }т)</span>
            </>
            : <>{ toTon(data.weight ?? 0) }т</> }
          className={clsx({ truckRun_selected: selected })}
        />
        <InfoCell
          label='Вес разгрузки'
          value={ data.finalWeightCorrection && data.finalWeightCorrection !== data.finalWeight
            ? <>
            { toTon(data.finalWeight ?? 0) }т <span style={{ color: '#EE6A5F' }}>({ toTon(data.finalWeightCorrection ?? 0) }т)</span>
          </>
            : <>{ toTon(data.finalWeight ?? 0) }т</> }
          className={clsx({ truckRun_selected: selected })}
        />
      </Stack>
      <Stack direction='row' spacing={2}>
        {hasError && <ReportProblem sx={{ color: '#EE6A5F', fontSize: '17px', mt: '0.2em' }}/>}
        { TruckRunStatus(data) }
      </Stack>
    </Stack>
  </InfoCard>)
}

type TruckRunFilter = 'checked' | 'not_checked'

const Badge = ({ label, count, color = 'white' }: { label: string, count?: number, color?: 'green' | 'white' | 'blue' }) => {
  return (<Stack direction='row' spacing={1} alignItems='center'>
      <span>{label}</span>
      { count !== undefined
        ? <span style={{
          height: '16px',
          width: '20px',
          borderRadius: '50%',
          background: color === 'green' ? '#6DCD45' : color === 'blue' ? '#0A5DFF' : color,
          color: color === 'white' ? '#0A5DFF' : '#FFFFFF',
          fontSize: '12px',
          fontWeight: 400,
          paddingBottom: '5px'
        }}>{count}</span>
        : <></> }
    </Stack>)
}

export default function DispatcherAct () {
  const [found, setFound] = useState<boolean>()
  const [data, setData] = useState<Data>()
  const [selectTruckRun, setSelectTruckRun] = useState<TruckRuns>()
  const [checkedStat, setCheckedStat] = useState({ checked: 0, notChecked: 0, change: 0, count: 0 })
  const [openRejectionModal, setOpenRejectionModal] = useState(false)
  const [rejectedActId, setRejectedActId] = useState<Id>()
  const [changeDiadoc, setChangeDiadoc] = useState<boolean>(false)
  const [changeRedirect, setChangeRedirect] = useState<boolean>(false)
  const [truckRunFilter, setTruckRunFilter] = useState<TruckRunFilter>('not_checked')

  const { id } = useParams()
  const { links, routesMap } = useMainRoutes()

  const navigate = useNavigate()

  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()

  const truckRunHasError = ({ finalWeight, finalWeightCorrection, weightCorrection, weight, unloadingAddress }: TruckRuns) =>
    (finalWeightCorrection !== undefined && finalWeight !== finalWeightCorrection) ||
    (weightCorrection !== undefined && weightCorrection !== weight) ||
    (unloadingAddress?.id !== data?.unloadingAddress?.id)

  const init = useCallback(() => {
    if (!isId(id)) {
      setFound(false)
      return
    }

    actAllGet(id).then(result => {
      if (result === null) {
        setFound(false)
      } else {
        setData(result)
        setChangeDiadoc(checkChangedInDiadocStatus(result))
        setChangeRedirect(result.truckRuns.some(({ unloadingAddress }) => unloadingAddress?.id !== result.unloadingAddress?.id))

        const { checked, change } = result.truckRuns.reduce((prev, cur) => {
          let { checked, change } = prev
          if (cur.isChecked) {
            checked++
          }

          if (cur.wasEdited) {
            change++
          }

          return { checked, change }
        }, { checked: 0, change: 0 })

        const notChecked = result.truckRuns.length - checked

        setCheckedStat({
          checked,
          notChecked,
          change,
          count: result.truckRuns.length
        })
        setFound(true)

        if (notChecked === 0) {
          setTruckRunFilter('checked')
        }
      }
    })
  }, [id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const handleSaveTruckRun = () => {
    init()
  }

  const handleBack = (actNum?: string) => {
    navigate(links.DISPATCHER_DOCUMENTS_ACTS_PAGE, { state: { actNum } })
  }

  const completeCheck = async () => {
    if (!isId(id)) {
      return
    }

    actAllChangeStatus(id, ActStatus.dispatcherChecked)
      .then(() => {
        handleResponseSuccess('УПД проверен')
        handleBack(data?.num)
      })
      .catch(() => {
        handleResponseFailure('Произошла ошибка')
      })
  }

  const cancel = () => {
    if (!isId(id)) {
      return
    }

    setRejectedActId(id)
    setOpenRejectionModal(true)
  }

  const isTruckRunFilter = (filter: TruckRunFilter) => truckRunFilter === filter

  if (found === undefined) {
    return <></>
  }

  if (!data) {
    return <></>
  }

  if (!found) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (<>
    <div className='dispatcherAct'>
      <div className='dispatcherAct_content'>
        <NavigatePanel
          breadcrumbs={{
            defaultItems: routesMap.getBreadcrumbs(links.DISPATCHER_DOCUMENTS_ACTS_PAGE),
            items: [{ title: data.num }]
          }}
          title={`УПД ${data.num}`}
          actions={<IconButton onClick={() => handleBack()}>
            <CloseIcon />
          </IconButton>}
        />
        { data.status === ActStatus.waitingSigning && <Box sx={{ background: '#ffffff', borderBottom: '1px solid #E2E8F0', padding: '1em 1.5em' }} >
          <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
            <Box>
              <Typography textTransform='uppercase' fontWeight={400} fontSize='16px'>
                Проверено: <span
                  style={{
                    textTransform: 'none',
                    color: '#0A5DFF'
                  }}
                >{checkedStat.checked} из {checkedStat.count}</span>
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              { checkedStat.change > 0 && <>
                <Warning sx={{ width: '16px', color: '#EE6A5F', mr: '5px' }} />
                <Typography fontWeight={500} fontSize='13px' color='#EE6A5F'>
                 Есть измененые рейсы, нужно отказать в подписании УПД!
                </Typography>
                </>}
            </Box>
            <Stack direction='row' spacing={2}>
              <Button
                variant='contained'
                disabled={(changeDiadoc || changeRedirect || checkedStat.checked !== checkedStat.count || !!checkedStat.change)}
                onClick={completeCheck}
              >Завершить проверку</Button>
              {/* <ActionsMenu actions={[
                {
                  caption: 'Отказать в подписании',
                  onClick: cancel,
                  icon: <ClearSharp />
                }
              ]} dontCollapse widthHeight='40px' /> */}
              <Button
                variant='contained'
                color='error'
                onClick={cancel}
              >Отказать в подписании</Button>
            </Stack>
          </Stack>
        </Box> }
        <div className='dispatcherAct_content_body'>
          { changeDiadoc && <Alert
              color='#EE6A5F'
              sx={{ mb: '1em' }}
              controls={<Button
                variant='contained'
                color='secondary'
                onClick={cancel}
              >Отказать в подписании</Button>}
            >
            <Typography fontWeight={600} fontSize='18px' color='#ffffff'>
            Некоторые документы были изменены с помощью Контур Диадок.
            </Typography>
          </Alert> }
          { changeRedirect && <Alert
              color='#EE6A5F'
              sx={{ mb: '1em' }}
              controls={<Button
                variant='contained'
                color='secondary'
                onClick={cancel}
              >Отказать в подписании</Button>}
            >
            <Typography fontWeight={600} fontSize='18px' color='#ffffff'>
            Некоторые рейсы были переадресованы после создания документов.
            </Typography>
          </Alert> }
          <Stack direction='row' spacing={2}>
            <InfoCard
              title='Об акте'
              items={[
                {
                  label: 'Перевозчик',
                  value: data.organization.fullName
                },
                { label: 'Заказчик', value: data.bid.customer.fullName },
                { label: 'Дата получения', value: TsToFormatDate(data.sendTs, 'dd.MM.yyyy HH:mm') },
                { label: 'Сумма акта', value: rub(data.totalSum) }
              ]}
              style={{ width: '50%' }}
            />
            <InfoCard title='Документы' style={{ width: '50%', margin: '6px 0 6px 10px' }}>
              <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={2} alignItems='center'>
                  <SquareButton variant='outlined' color='secondary' onClick={() => download(data.filename)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
                  <Typography fontWeight={600} fontSize='14px' width={150}>УПД {data.num}</Typography>
                  {getChipsByStatus(data.status)}
                </Stack>
                { data.registryFilename && <Stack direction='row' spacing={2} alignItems='center'>
                  <SquareButton variant='outlined' color='secondary' onClick={() => download(data.registryFilename as string)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
                  <Typography fontWeight={600} fontSize='14px' width={150}>Реестр оказанных услуг</Typography>
                  {data.registryStatus && getChipsByStatus(data.registryStatus)}
                </Stack> }
                { data.invoiceFilename && <Stack direction='row' spacing={2} alignItems='center'>
                  <SquareButton variant='outlined' color='secondary' onClick={() => download(data.invoiceFilename as string)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
                  <Typography fontWeight={600} fontSize='14px' width={150}>Счет</Typography>
                  {data.invoiceStatus && getChipsByStatus(data.invoiceStatus)}
                </Stack> }
                { data.shortageFilename && <Stack direction='row' spacing={2} alignItems='center'>
                  <SquareButton variant='outlined' color='secondary' onClick={() => download(data.shortageFilename as string)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
                  <Typography fontWeight={600} fontSize='14px' width={150}>Отчёт по недостачам</Typography>
                  {data.shortageStatus && getChipsByStatus(data.shortageStatus)}
                </Stack> }
                { data.agreementFilename && <Stack direction='row' spacing={2} alignItems='center'>
                  <SquareButton variant='outlined' color='secondary' onClick={() => download(data.agreementFilename as string)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
                  <Typography fontWeight={600} fontSize='14px' width={150}>Соглашение</Typography>
                  {data.agreementStatus && getChipsByStatus(data.agreementStatus)}
                </Stack> }
              </Stack>
            </InfoCard>
          </Stack>
          <Box>
            <Typography fontWeight={600} fontSize='20px' mt='1em'>Рейсы</Typography>
            <Box mt={1} mb={2}>
              <ButtonGroup variant='outlined' size='small'>
                <Button
                  variant={isTruckRunFilter('not_checked') ? 'contained' : 'outlined'}
                  onClick={() => setTruckRunFilter('not_checked')}
                  color={!isTruckRunFilter('not_checked') ? 'secondary' : undefined}
                  disabled={checkedStat.notChecked === 0}
                ><Badge
                  label='Требуется проверка'
                  count={checkedStat.notChecked}
                  color={isTruckRunFilter('not_checked') ? 'white' : 'blue'}
                /></Button>
                <Button
                  variant={isTruckRunFilter('checked') ? 'contained' : 'outlined'}
                  onClick={() => setTruckRunFilter('checked')}
                  color={!isTruckRunFilter('checked') ? 'secondary' : undefined}
                  disabled={checkedStat.checked === 0}
                ><Badge
                  label='Проверены'
                  count={checkedStat.checked}
                  color={isTruckRunFilter('checked') ? 'white' : 'green'}
                /></Button>
              </ButtonGroup>
            </Box>
            <div className='dispatcherAct_content_body_truckRunsItems'>
            { data.truckRuns
              .filter(item => isTruckRunFilter('checked') ? item.isChecked : !item.isChecked)
              .map(item => (<TruckRunItem
                data={item}
                key={item.id}
                onClick={() => setSelectTruckRun(item)}
                selected={selectTruckRun?.id === item.id}
                disabled={changeDiadoc || changeRedirect}
                hasError={truckRunHasError(item)}
              />))}
            </div>
          </Box>
        </div>
      </div>
    </div>
    <ActDispatcherRejectionWizard
      open={openRejectionModal}
      actId={rejectedActId}
      problem={changeDiadoc || changeRedirect}
      onClose={() => {
        setOpenRejectionModal(false)
        handleBack()
      }}
    />
    <TruckRunEditModal
      data={selectTruckRun}
      utdFilename={data.filename}
      setData={setSelectTruckRun}
      onSave={handleSaveTruckRun}
    />
  </>)
}
