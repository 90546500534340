import { Visibility } from '@mui/icons-material'
import { Button, Chip, Stack, Typography } from '@mui/material'
import { Profile, ProfileAll, ProfileContractStatus } from 'api/profile'
import InfoCard from 'ui/InfoCard'
import TsToFormatDate from 'util/TsToFormatDate'
import CarrierContractWizard, { Customer } from 'ui/CarrierContractWizard'
import { useState } from 'react'
import download from 'util/download'
import { useAuthContext } from 'AuthContext'
import SquareButton from 'ui/SquareButton'
import None from 'ui/None'

export interface Params {
  profile?: Profile | ProfileAll
  signing?: boolean
}

export default function CrrierContracts ({ profile, signing }: Params) {
  const [contractModal, setContractModal] = useState<Customer>()
  const { profileRefresh } = useAuthContext()

  if (profile === undefined) {
    return (<></>)
  }

  return (<>
    {signing && <CarrierContractWizard
      open={contractModal !== undefined}
      customer={contractModal}
      onClose={() => {
        setContractModal(undefined)
        profileRefresh()
      }}
      signing
    />}
    <InfoCard title='Договора'>
      <>
      { profile.contracts && profile.contracts.length > 0
        ? <Stack direction='column' spacing={2}>
        { profile.contracts.map((item) => (<Stack direction='row' spacing={2} alignItems='center' key={item.num}>
          <Typography>Договор №{item.num} от {TsToFormatDate(item.createTs, 'dd.MM.yyyy')} с {item.customer.fullName}</Typography>
          {signing
            ? <>
              { item.status === ProfileContractStatus.signed
                ? <>{ item.filename !== undefined && <SquareButton size='small' variant='outlined' color='secondary' onClick={() => download(item.filename as string)} ><Visibility sx={{ color: '#EBEBEB' }} /></SquareButton> }</>
                : <Button size='small' color='primary' variant='contained' onClick={() => setContractModal(item.customer)}>Подписать</Button>
              }
            </>
            : <>
              { item.filename !== undefined && <SquareButton size='small' variant='outlined' color='secondary' onClick={() => download(item.filename as string)} ><Visibility sx={{ color: '#EBEBEB' }} /></SquareButton> }
              { item.status === ProfileContractStatus.new && <Chip label="Не подписан" color="secondary" variant='outlined' size='small' /> }
              { item.status === ProfileContractStatus.signed && <Chip label="Подписан" color="primary" variant='outlined' size='small' />}
            </>}
        </Stack>))}
      </Stack>
        : <Stack minHeight='50px'>
        <None desc="Договора отсутствуют" />
      </Stack>}
      </>
    </InfoCard>
  </>)
}
