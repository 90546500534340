import './styles.sass'
import { useEffect, useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import Modal from 'ui/Modal'
import { Id } from 'api/Id'
import Upload from 'ui/Upload'
import { shippingComplete } from 'api/shipping'

interface Params {
  id?: Id
  onClose: (done?: boolean) => void
  onDone: () => void
}

export default function CompleteModal ({ id, onClose, onDone }: Params) {
  const [done, setDone] = useState<boolean>(false)
  const [scanBills, setScanBills] = useState<string[]>()

  useEffect(() => {
    setScanBills(undefined)
    setDone(false)
  }, [])

  const save = () => {
    if (id === undefined || scanBills === undefined || scanBills.length === 0) {
      return
    }

    shippingComplete(id, scanBills).then(() => {
      setScanBills(undefined)
      setDone(true)
    })
  }

  const close = () => {
    setScanBills(undefined)
    const d = done
    onClose(d)
    setDone(false)
  }

  return <Modal
    title={done ? 'Рейс Завершен!' : 'Загрузка фото ТрН/ТТН'}
    open={id !== undefined}
    onClose={close}
    content={<Stack sx={{ minWidth: '500px' }}>
      { done
        ? <>
          <Box sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
            color: '#B2B2B2'
          }}>Фото ТрН/ТТН отправлено, рейс переведен в статус «Завершен»</Box>
        </>
        : <>
          <Box sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
            color: '#B2B2B2',
            mb: 2
          }}>После загрузки фото ТрН/ТТН, рейс будет переведен в статус «Завершен»</Box>
          <Stack>
            {scanBills && scanBills.map((scanBill, idx) =>
              <Upload key={idx} value={scanBill} onChange={() => setScanBills((prev) => {
                const scanBills = prev ? [...prev] : []
                scanBills.splice(idx, 1)
                return scanBills.length > 0 ? scanBills : undefined
              })} />
            )}
            <Upload key='new' onChange={(value) => setScanBills((prev) => [...(prev ?? []), value])} />
          </Stack>
        </>}
    </Stack>}
    actions={ done
      ? <>
        <Button variant='contained' onClick={() => {
          setScanBills(undefined)
          onDone()
          setDone(false)
        }}>К списку рейсов</Button>
      </>
      : <>
        <Button color='secondary' variant='outlined' onClick={close}>Отменить</Button>
        <Button variant='contained' onClick={save} disabled={scanBills === undefined || scanBills.length === 0}>Отправить</Button>
      </>
    }
  />
}
