import './styles.sass'
import { isId } from 'api/Id'
import { useAuthContext } from 'AuthContext'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useValidate from 'validation/validate'
import schema, { clear } from 'validation/Customer'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { Button, Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import { CustomerForUpdate, customerUpdate, customerCreate, customerGet, CustomerStatus, Customer } from 'api/customer'
import meta from 'entity/Customer'
import onlyFields from 'util/onlyFields'
import { ORGANIZATION_KPP_MASK } from 'common/Mask/Organization'

type UpdateParams = Omit<CustomerForUpdate, 'id'>
type MiscData = Pick<Customer, 'externalId' | 'externalName' | 'externalType' | 'externalPhones' | 'externalEmails' | 'hasDiadocPassword'>

export default function AdminCustomer () {
  const { id } = useParams()

  const [data, setData] = useState<UpdateParams>()
  const [isNew, setIsNew] = useState(true)
  const [edit, setEdit] = useState(true)

  const [miscData, setMiscData] = useState<MiscData>()

  const navigate = useNavigate()
  const { check, errors } = useValidate(schema)
  const { links } = useMainRoutes()

  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()

  const save = useCallback(async () => {
    if (data === undefined) {
      return
    }

    const d = onlyFields(data, 'inn', 'okpo',
      'fullName', 'bankName', 'correspondentAccountNumber',
      'checkingAccountNumber', 'bik', 'kpp',
      'organizationHeadName', 'legalAddress', 'phone',
      'diadocBoxId', 'diadocUser', 'diadocPassword')
    const clearData = clear(d)

    if (!check(clearData)) {
      return
    }

    if (isId(id)) {
      const result = await customerUpdate({ id, ...clearData })

      if (result) {
        handleResponseSuccess('Данные заказчика изменены')
        navigate(links.ADMIN_CUSTOMERS_PAGE)
      }
    } else {
      const id = await customerCreate(clearData)

      if (id !== null) {
        handleResponseSuccess('Заказчик создан')
        navigate(links.ADMIN_CUSTOMERS_PAGE)
      }
    }
  }, [check, data, id, handleResponseSuccess, navigate, links.ADMIN_CUSTOMERS_PAGE])

  const init = useCallback(async () => {
    if (id === 'add') {
      setData({})
      return
    }

    const result = isId(id) ? await customerGet(id) : null

    if (result !== null) {
      const {
        id, status, externalId, externalName,
        externalType, externalPhones, externalEmails, hasDiadocPassword, ...data
      } = result

      if (externalId || externalName || externalType || externalPhones || externalEmails || hasDiadocPassword) {
        setMiscData({ externalId, externalName, externalType, externalPhones, externalEmails, hasDiadocPassword })
      }

      setData(data)
      setIsNew(false)
      setEdit(status !== CustomerStatus.archive)
    } else {
      handleResponseFailure('Заказчик не найден')
    }
  }, [handleResponseFailure, id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const updateString = (key: keyof UpdateParams) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setData({ ...data, [key]: e.target.value })
    }
  }

  if (data === undefined) {
    return <></>
  }

  return (
    <div className='adminCustomer'>
      <NavigatePanel
        title={isNew
          ? 'Новый заказчик'
          : data.fullName
        }
        breadcrumbs={{
          items: [
            { title: 'Заказчики', to: links.ADMIN_CUSTOMERS_PAGE },
            { title: isNew ? 'Добавить заказчика' : data.fullName ?? '' }
          ]
        }}
        actions={ edit
          ? <Stack direction='row' spacing={2} justifyContent='end'>
            <Button variant='outlined' color='secondary' size='small' onClick={() => navigate(-1)}>
            Отменить
            </Button>
            <Button variant='contained' color='success' size='small' onClick={save}>
              Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
            </Button>
          </Stack>
          : undefined
        }
      />
      <div className='adminCustomer__body'>
        <div className='adminCustomer__content'>
          <InfoCard title='Реквизиты компаний'><>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='fullName'
                label={meta.title('fullName')}
                placeholder='​Название'
                value={data.fullName}
                onChange={updateString('fullName')}
                width='33%'
                errors={errors}
                disabled={!edit}
              />
              <TextField
                name='inn'
                label={meta.title('inn')}
                placeholder='XXXXXXXXXX'
                value={data.inn}
                onChange={updateString('inn')}
                width='33%'
                errors={errors}
                maskParams={{
                  mask: meta.mask('inn')
                }}
                disabled={!edit}
              />
              <TextField
                name='okpo'
                width='33%'
                label={meta.title('okpo')}
                value={data.okpo}
                onChange={updateString('okpo')}
                placeholder='XXXXXXXX'
                maskParams={{
                  mask: meta.mask('okpo')
                }}
                errors={errors}
                disabled={!edit}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='bankName'
                label={meta.title('bankName')}
                placeholder='Наименование банка'
                value={data.bankName}
                onChange={updateString('bankName')}
                width='33%'
                errors={errors}
                disabled={!edit}
              />
              <TextField
                name='correspondentAccountNumber'
                label={meta.title('correspondentAccountNumber')}
                placeholder='XXXXXXXXXXXXXXXXXXXX'
                value={data.correspondentAccountNumber}
                onChange={updateString('correspondentAccountNumber')}
                width='33%'
                errors={errors}
                maskParams={{
                  mask: meta.mask('correspondentAccountNumber')
                }}
                disabled={!edit}
              />
              <TextField
                name='checkingAccountNumber'
                label={meta.title('checkingAccountNumber')}
                placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
                value={data.checkingAccountNumber}
                onChange={updateString('checkingAccountNumber')}
                width='33%'
                errors={errors}
                maskParams={{
                  mask: meta.mask('checkingAccountNumber')
                }}
                disabled={!edit}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='bik'
                label={meta.title('bik')}
                placeholder='XX-XX-XX-XXX'
                value={data.bik}
                onChange={updateString('bik')}
                width='33%'
                errors={errors}
                maskParams={{
                  mask: meta.mask('bik')
                }}
                disabled={!edit}
              />
              <TextField
                name='kpp'
                label={meta.title('kpp')}
                placeholder='XXXXXXXXX'
                value={data.kpp}
                onChange={updateString('kpp')}
                width='33%'
                errors={errors}
                maskParams={{
                  mask: ORGANIZATION_KPP_MASK
                }}
                disabled={!edit}
              />
              <TextField
                name='organizationHeadName'
                label={meta.title('organizationHeadName')}
                placeholder='Лицо, имеющие право подписи без доверенности'
                value={data.organizationHeadName}
                onChange={updateString('organizationHeadName')}
                width='33%'
                errors={errors}
                disabled={!edit}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='legalAddress'
                label={meta.title('legalAddress')}
                placeholder='Юридический адрес'
                value={data.legalAddress}
                onChange={updateString('legalAddress')}
                width='67%'
                errors={errors}
                disabled={!edit}
              />
              <TextField
                label={meta.title('phone')}
                name='phone'
                width='33%'
                placeholder='+7 (999) 999-99-99'
                value={data.phone}
                onChange={updateString('phone')}
                maskParams={{
                  mask: meta.mask('phone')
                }}
                errors={errors}
                disabled={!edit}
              />
            </Stack>
          </></InfoCard>
          { miscData && <InfoCard title='КИС'><>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                label='ID'
                placeholder='ID'
                value={miscData.externalId}
                width='100%'
                disabled={true}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <TextField
                name='externalName'
                label='Наименование'
                value={miscData.externalName}
                width='25%'
                disabled={true}
              />
              <TextField
                name='externalType'
                label='Тип'
                value={miscData.externalType}
                width='25%'
                disabled={true}
              />
              <TextField
                name='externalPhones'
                label='Телефоны'
                value={miscData.externalPhones}
                width='25%'
                disabled={true}
              />
              <TextField
                name='externalEmails'
                label='Email'
                value={miscData.externalEmails}
                width='25%'
                disabled={true}
              />
            </Stack>
          </></InfoCard> }
          <InfoCard title='Диадок' >
            <>
              <Stack direction='row' gap={2}>
                <TextField
                  name='diadocBoxId'
                  label='ID ящика в Диадок'
                  width='100%'
                  value={data.diadocBoxId}
                  errors={errors}
                  onChange={updateString('diadocBoxId')}
                />
              </Stack>
              {/* <Typography fontWeight={600} mb='10px'>Настройки робота</Typography>
              <Stack direction='row' gap={2}>
                <TextField
                  name='diadocUser'
                  label='Пользователь Диадок'
                  width='50%'
                  value={data.diadocUser}
                  errors={errors}
                  onChange={updateString('diadocUser')}
                />
                <TextField
                  name='diadocPassword'
                  label='Пароль'
                  hint={miscData?.hasDiadocPassword
                    ? 'Для смены пароля введите новый'
                    : <span style={{ color: 'red' }}>Пароль не задан</span>
                  }
                  placeholder={miscData?.hasDiadocPassword ? 'Введите новый пароль' : 'Введите пароль'}
                  width='50%'
                  value={data.diadocPassword}
                  errors={errors}
                  onChange={updateString('diadocPassword')}
                  type='password'
                />
              </Stack> */}
            </>
          </InfoCard>
        </div>
      </div>
    </div>
  )
}
