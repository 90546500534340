import { ShippingTruckRun, ShippingTruckRunStatus } from 'api/shipping'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import './styles.sass'
import { personName } from 'util/personName'
import InfoCell from 'ui/InfoCell'
import trailerName from 'util/trailerName'
import vehicleName from 'util/vehicleName'
import { Button, Checkbox } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import { ReactNode, useState } from 'react'
import CompleteModal from 'pages/CarrierTrip/CompleteModal'
import { Id } from 'api/Id'
import { toTon } from 'util/weight'
import { RangeDate } from 'ui/DatePicker'
import { AddComment, QrCode2 } from '@mui/icons-material'
import SquareButton from 'ui/SquareButton'
import ChipStatus from 'ui/ChipStatus'

export type StatusView = (status: ShippingTruckRunStatus) => ReactNode
export interface Highlight {
  billNumber?: string,
  billTs?: RangeDate
}

export interface ParamsRow {
  data: ShippingTruckRun
  highlight?: Highlight
  selected?: boolean
  onChange?: () => void
  statusView?: StatusView
  onAddComment?: (data: ShippingTruckRun) => void
  onViewQRCode?: (data: ShippingTruckRun) => void
  onRefuse?: (data: ShippingTruckRun) => void
  onSelect?: (data: ShippingTruckRun, checked: boolean) => void
  onOpenTruckRun?: (data: ShippingTruckRun) => void
}

const Row = ({ data, highlight, selected, onChange, statusView, onAddComment, onViewQRCode, onRefuse, onSelect, onOpenTruckRun }: ParamsRow) => {
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const [completeModalId, setCompleteModalId] = useState<Id>()

  const goto = () => {
    if (onOpenTruckRun) {
      onOpenTruckRun(data)
      return
    }

    const link = [ShippingTruckRunStatus.new, ShippingTruckRunStatus.archiveReject].includes(data.status)
      ? links.CARRIER_BIDS_SHIPPING_INFO_PAGE
      : links.CARRIER_TRIPS_PAGE
    navigate(`${link}/${data.id}`)
  }

  const isHighlight =
    (highlight?.billNumber && data.billNumber === highlight?.billNumber) ||
    (highlight?.billTs?.startTs && data.billTs && data.billTs >= highlight.billTs.startTs) ||
    (highlight?.billTs?.endTs && data.billTs && data.billTs <= highlight.billTs.startTs)

  return (
    <>
      <CompleteModal
        id={completeModalId}
        onClose={(done) => {
          setCompleteModalId(undefined)
          if (done) {
            !!onChange && onChange()
          }
        }}
        onDone={() => {
          setCompleteModalId(undefined)
          !!onChange && onChange()
        }}
      />
      <div className='truckRunsInfo_row' style={isHighlight ? { border: '1px solid #0A5DFF' } : undefined}>
        <div className='truckRunsInfo_row_infoCellItems'>
          { onSelect && <Checkbox checked={selected} onChange={(e) => onSelect(data, e.target.checked) } sx={{ ml: 0, pl: 0 }} /> }
          <InfoCell label='Номер рейса' value={data.num} />
          <InfoCell label='Дата погрузки' value={formatDate(data.actualLoadingTs ?? data.loadingTs)} />
          <InfoCell label='Транспортное средство' value={vehicleName(data.vehicle)} />
          <InfoCell label='Прицеп' value={trailerName(data.trailer)} />
          <InfoCell label='Водитель' value={personName(data.driver)} />
          { data.status === ShippingTruckRunStatus.way && data.weight &&
            <InfoCell label='Погружено' value={`${toTon(data.weight)} т.`} /> }
          { [ShippingTruckRunStatus.arrived, ShippingTruckRunStatus.completed, ShippingTruckRunStatus.archiveAct].includes(data.status) && <>
          { data.unloadingTs && <InfoCell label='Дата выгрузки' value={formatDate(data.unloadingTs)} /> }
          { data.finalWeight && <InfoCell label='Выгружено' value={`${toTon(data.finalWeight)} т.`} /> }
          { data.billNumber && <InfoCell label='Номер ТТН' value={data.billNumber} /> }
          </>}
        </div>
        <div className='truckRunsInfo_row_action'>
        { data.unloadingAddress && <ChipStatus color='yellowBorder' label='Переадресация' style={{ marginRight: '1em' }}/> }
        { statusView ? statusView(data.status) : ChipTruckRunStatus(data.status) }
        { [ShippingTruckRunStatus.new, ShippingTruckRunStatus.confirmed].includes(data.status) && <>
          { onRefuse && <Button
            variant='outlined'
            size='small'
            color='secondary'
            sx={{ ml: '10px', color: '#475569' }}
            onClick={() => onRefuse(data)}
          >Отозвать отклик</Button>}
          { onViewQRCode && data.reservedUnloadingSlot && <SquareButton
            variant='contained'
            widthHeight='36px'
            color='primary'
            sx={{ ml: '10px' }}
            onClick={() => onViewQRCode(data)}
          ><QrCode2 /></SquareButton> }
        </>}
        { data.status === ShippingTruckRunStatus.way && onAddComment !== undefined && <SquareButton
          widthHeight='36px'
          variant='contained'
          size='small'
          color='secondary'
          sx={{ ml: '10px', color: '#475569' }}
          onClick={() => { onAddComment(data) }}
        ><AddComment /></SquareButton>}
        { data.status === ShippingTruckRunStatus.arrived && (!data.unloadingAddress || data.consignee) && <Button
          component="label"
          variant='contained'
          size='small'
          sx={{
            maxWidth: '36px',
            minWidth: '36px',
            ml: '10px'
          }}
          onClick={() => setCompleteModalId(data.id)}
        >
          <NoteAddIcon />
        </Button>}
        <Button
          variant='contained'
          size='small'
          onClick={goto}
          sx={{
            maxWidth: '36px',
            minWidth: '36px',
            ml: '10px'
          }}
        >
          <MoreHorizIcon />
        </Button>
        </div>
      </div>
    </>
  )
}

interface TruckRunInfoParams {
  data: ShippingTruckRun[]
  highlight?: Highlight
  selectedIds?: Id[]
  onChange?: () => void
  title?: ReactNode
  statusView?: StatusView
  onAddComment?: (data: ShippingTruckRun) => void
  onViewQRCode?: (data: ShippingTruckRun) => void
  onRefuse?: (data: ShippingTruckRun) => void
  onSelect?: (data: ShippingTruckRun, checked: boolean) => void
  onOpenTruckRun?: (data: ShippingTruckRun) => void
}

export default function TruckRunInfo ({
  data, onChange, highlight, title, selectedIds,
  statusView, onAddComment, onViewQRCode,
  onRefuse, onSelect, onOpenTruckRun
}: TruckRunInfoParams) {
  return (
    <>
      <div className='truckRunsInfo'>
        { title && <div className='truckRunsInfo_header'>{title}</div> }
        { data.map((v, i) => (<Row
            data={v}
            key={i}
            highlight={highlight}
            selected={selectedIds?.includes(v.id)}
            onChange={onChange}
            statusView={statusView}
            onAddComment={onAddComment}
            onViewQRCode={onViewQRCode}
            onRefuse={onRefuse}
            onSelect={onSelect}
            onOpenTruckRun={onOpenTruckRun}
          />)) }
      </div>
    </>
  )
}
