import { Box, Button, IconButton, Typography } from '@mui/material'
import {
  shippingTruckRunUpdate,
  ShippingTruckRunExtBid,
  shippingAllGet,
  ShippingTruckRunStatus as Status,
  isArchiveStatus
} from 'api/shipping'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import './styles.sass'
import { Id, isId } from 'api/Id'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import { CheckCircle, Close, Edit } from '@mui/icons-material'
import InfoCard, { InfoItemParams } from 'ui/InfoCard'
import Map from 'ui/Osm'
import TsToFormatDate from 'util/TsToFormatDate'
import rub from 'util/formatRub'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import { useAuthContext } from 'AuthContext'
import FullAddress from 'ui/FullAddress'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import ChipStatus from 'ui/ChipStatus'
import formatPhone from 'util/formatPhone'
import formateParties from 'util/formateParties'
import { Stack } from '@mui/system'
import TruckRunEditModel from 'ui/TruckRunEditModel'
import Alert from 'ui/Alert'
import formatCargo from 'util/formatCargo'
import formatScaleLength from 'util/formatScaleLength'
import formatScaleMaxLoad from 'util/formatScaleMaxLoad'
import { toKilometers } from 'util/distance'
import { toTon } from 'util/weight'
import { Interface } from 'api/Interface'
import { BlackListEntry } from 'api/black-list'
import blTypeMap from 'common/BlackList/typeMap'
import blStatusMap from 'common/BlackList/statusMap'
import InfoIcon from '@mui/icons-material/Info'
import { BidCargo, bidCargoList } from 'api/bid'

const getInfo = (data: ShippingTruckRunExtBid) => {
  const result: InfoItemParams[] = []
  result.push(
    { label: 'Статус', value: data.status === Status.confirmed ? <ChipStatus label='Запланирован' /> : ChipTruckRunStatus(data.status) },
    { label: 'Дата погрузки', value: TsToFormatDate(data.actualLoadingTs ?? data.loadingTs, 'dd MMMM yyyy') },
    { label: 'Груз', value: formatCargo(data.bid.cargo) },
    { label: 'Расстояние', value: `${toKilometers(data.bid.distance)} км` },
    { label: 'Стоимость перевозки тн/км', value: rub(data.bid.costTonKm) },
    { label: 'Cтоимость перевозки 1 тн', value: rub(data.bid.costTon) }
  )

  !!data.billTs && result.push({ label: 'Дата ТрН/ТТН', value: TsToFormatDate(data.billTs, 'dd MMMM yyyy') })
  !!data.billNumber && result.push({ label: 'Номер Трн/ТТН', value: data.billNumber })
  !!data.weight && result.push({ label: 'Вес погрузки', value: `${toTon(data.weight)} т.` })
  !!data.costWeight && result.push({ label: 'Стоимость груза на погрузке', value: rub(data.costWeight) })
  !!data.unloadingTs && result.push({ label: 'Дата выгрузки', value: TsToFormatDate(data.unloadingTs, 'dd MMMM yyyy') })
  !!data.finalWeight && result.push({ label: 'Вес разгрузки', value: `${toTon(data.finalWeight)} т.` })
  !!data.cost && result.push({ label: 'Стоимость груза на разгрузке', value: rub(data.cost) })

  result.push(
    { label: 'Заказчик перевозки', value: data.bid.customer.fullName },
    { label: 'Грузим +10%', value: data.bid.plusTen ? 'Да' : 'Нет' },
    { label: 'ТС', value: `${vehicleName(data.vehicle)} ${data.vehicle.color}` },
    { label: 'Прицеп', value: trailerName(data.trailer, { color: true }) },
    { label: 'Водитель', value: personName(data.driver) },
    { label: 'Номер телефона', value: formatPhone(data.driver.phone) }
  )

  return result
}

const BlackListView = ({ blackList }: { blackList?: BlackListEntry[] }) => {
  if (blackList === undefined) {
    return <></>
  }

  if (blackList.length === 0) {
    return <></>
  }

  return <Stack direction='column' gap={1} m='1em 1.5em' >
  { blackList.map(item => {
    return (<Alert color='#EE6A5F' key={`bl-entry-${item.id}`}>
      <Stack direction='column' color='#FFFFFF'>
        <Typography fontSize='18px' fontWeight={600} mb='4px'>
        { blTypeMap.get(item.type)}: { blStatusMap.get(item.status)}
        </Typography>
        <Typography fontSize='14px' fontWeight={400}>{item.description}</Typography>
      </Stack>
    </Alert>)
  }) }
  </Stack>
}

export default function DispatcherTrip () {
  const { handleResponseFailure, handleResponseSuccess, currentInterface } = useAuthContext()
  const [data, setData] = useState<ShippingTruckRunExtBid>()
  const [found, setFound] = useState<boolean>()
  const [editId, setEditId] = useState<Id>()
  const [cargoList, setCargoList] = useState<BidCargo[]>([])

  const params = useParams()
  const navigate = useNavigate()
  const { links, routesMap } = useMainRoutes()

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.CARRIER_TRIPS_PAGE),
    [links, routesMap]
  )

  const isShipping = data?.status !== Status.new
  const isChief = currentInterface === Interface.chief_dispatcher
  const isEdit = isChief && data?.status ? isShipping && (!isArchiveStatus(data.status) || data.status === Status.archiveOutdated) : false

  const title = isShipping
    ? `Рейс ${data?.num} по перевозке ${data?.bid.num} от ${TsToFormatDate(data?.loadingTs, 'dd.MM')}`
    : `Отклик ${data?.num} по перевозке ${data?.bid.num} от ${TsToFormatDate(data?.loadingTs, 'dd.MM')}`

  useEffect(() => {
    if (found !== undefined) {
      return
    }

    if (!isId(params.id)) {
      setFound(false)
      return
    }

    bidCargoList().then(setCargoList)
    shippingAllGet(params.id).then(result => {
      if (result === null) {
        setFound(false)
      } else {
        setData(result)
        setFound(true)
      }
    })
  }, [found, params.id])

  const handleBack = () => {
    navigate(-1)
  }

  const handleError = () => handleResponseFailure('Невозможно изменить статус')

  const confrimed = async () => {
    if (data === undefined) {
      return
    }

    const { success } = await shippingTruckRunUpdate({ id: data.id, status: Status.confirmed })

    if (success) {
      handleResponseSuccess('Отклик подтвержден')
      setFound(undefined)
      return
    }

    handleError()
  }

  const reject = async () => {
    if (data === undefined) {
      return
    }

    const { success } = await shippingTruckRunUpdate({ id: data.id, status: Status.archiveReject })

    if (success) {
      handleResponseSuccess('Отклик отклонен')
      setFound(undefined)
      return
    }

    handleError()
  }

  if (found === undefined) {
    return <></>
  }

  if (!found) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (
    <>
      <div className='carrierTrip'>
        <div className='carrierTrip_content'>
          <NavigatePanel
            breadcrumbs={{
              defaultItems: breadcrumbsItems,
              items: [{ title }]
            }}
            title={title}
            actions={<Stack direction='row' justifyContent='flex-end'>
              { isEdit && <IconButton onClick={() => setEditId(data?.id)}>
                <Edit />
              </IconButton> }
              <IconButton onClick={() => handleBack()}>
                <Close />
              </IconButton>
            </Stack>}
          />
          <div className='carrierTrip_content_info'>
            { data && <>
              <BlackListView blackList={data.blackList} />
              { data.status === Status.archiveProblem && <Alert color='#EE6A5F' sx={{ margin: '1em 1.5em' }} >
                <>
                  <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Завершен диспетчером</Typography>
                  <Typography fontWeight={400} fontSize='13px' color='#ffffff'>Причина: {data.problemComment}</Typography>
                </>
              </Alert> }
              { data.status === Status.way && data.hasLongToWay && <Alert color='#FFA800' sx={{ margin: '1em 1.5em' }} >
                <>
                  <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Рейс находится в пути более двух суток</Typography>
                </>
              </Alert> }
              { data.unloadingAddress && <Alert color='#ffa800' sx={{ margin: '1em 1.5em' }} >
              <>
                <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Рейс был переадресован</Typography>
              </>
            </Alert> }
              <InfoCard className='carrierTrip_content_infoCard'
                title='Информация о рейсе'
                items={getInfo(data)}
                actions={<>
                { data.status === Status.new && <Stack direction='row' spacing={2}>
                    <Button variant='contained' color='primary' onClick={confrimed}>Подтвердить</Button>
                    <Button variant='outlined' color='secondary' onClick={reject}>Отклонить</Button>
                  </Stack> }
                </>}
              />
              <InfoCard className='carrierTrip_content_infoCard'
                title={<Stack direction='row' alignItems='center'>
                  <Box>Маршрут</Box>
                  { data.unloadingAddress && <Box sx={{ color: '#ffa800', mt: 0.5, ml: 1 }}><InfoIcon /></Box> }
                </Stack>}
                items={[
                  { label: 'Место погрузки', value: <FullAddress address={data.bid.loadingAddress} /> },
                  {
                    label: 'Место разгрузки:',
                    value: data.unloadingAddress
                      ? <>
                        <Box><FullAddress address={data.unloadingAddress} /></Box>
                        <Box sx={{ color: '#B2B2B2', mt: 1 }}><FullAddress address={data.bid.unloadingAddress} /></Box>
                      </>
                      : <FullAddress address={data.bid.unloadingAddress} />
                  }
                ]}
              />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте погрузки'
                items={[
                  { label: 'Способ погрузки', value: data.bid.loadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.loadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.loadingWeighing) },
                  { label: 'Грузоотправитель', value: formateParties(data.bid.consignor) }
                ]} />
              <InfoCard className='carrierBid_content_infoCard'
                title='Информация о пункте разгрузки'
                items={[
                  { label: 'Способ разгрузки', value: data.bid.unloadingMethod },
                  { label: 'Длина весов:', value: formatScaleLength(data.bid.unloadingWeighing) },
                  { label: 'Грузоподъемность весов:', value: formatScaleMaxLoad(data.bid.unloadingWeighing) },
                  { label: 'Грузополучатель', value: formateParties(data.consignee ?? data.bid.consignee) },
                  { label: 'Разрешенная недостача, тн', value: data.bid.underweight ? toTon(data.bid.underweight) : '-' },
                  { label: 'В ГК "Астон"', value: (data.unloadingAddress ?? data.bid.unloadingAddress).isPart ? <CheckCircle sx={{ color: '#3EAE49' }} /> : '' }
                ]}
              />
              { data.forwarder && <InfoCard className='carrierTrip_content_infoCard'
                title='Экспедитор'
                items={[
                  { label: 'ФИО', value: personName(data.forwarder) },
                  { label: 'Номер телефона', value: formatPhone(data.forwarder.phone) }
                ]}
              /> }
              { !data.forwarder && data.forwarders && data.forwarders.length > 0 && <InfoCard className='carrierTrip_content_infoCard'
                title='Экспедиторы'
                items={data.forwarders.map(item => ({ label: personName(item), value: formatPhone(item.phone) }))}
              /> }
            </>}
          </div>
        </div>
        <div className='carrierTrip_map'>
        { data && <Map route={{ from: data.bid.loadingAddress.coordinates, to: (data.unloadingAddress ?? data.bid.unloadingAddress).coordinates }} /> }
        </div>
      </div>
      <TruckRunEditModel
        cargoList={cargoList}
        id={editId}
        onClose={() => setEditId(undefined)}
        onSave={() => setFound(undefined)}
      />
    </>
  )
}
