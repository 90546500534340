import { Edit, Visibility } from '@mui/icons-material'
import { Button, Chip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { Id, isId } from 'api/Id'
import { Customer, customerDispatcherList } from 'api/customer'
import { ProfileAll, ProfileContractCreate, ProfileContractStatus, profileAllUpdate } from 'api/profile'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import Modal from 'ui/Modal'
import None from 'ui/None'
import Select from 'ui/Select'
import SquareButton from 'ui/SquareButton'
import TextField from 'ui/TextField'
import TsToFormatDate from 'util/TsToFormatDate'
import download from 'util/download'
import useValidate from 'validation/validate'
import schema from 'validation/CarrierContractCreate'
import Upload from 'ui/Upload'
import DatePicker from 'ui/DatePicker'
import { useAuthContext } from 'AuthContext'
import { UserStatus } from 'api/UserStatus'
import { Interface } from 'api/Interface'

export interface Params {
  profile?: ProfileAll
  onSave: () => void
}

export default function Contracts ({ profile, onSave }: Params) {
  const [contracts, setContarcts] = useState<ProfileContractCreate[]>([])
  const [customers, setCustomers] = useState<Customer[]>([])
  const [newContract, setNewContract] = useState<Partial<ProfileContractCreate>>()
  const [isEdit, setEdit] = useState<boolean>(false)

  const { check, errors, cleanErrors } = useValidate(schema)
  const { handleResponseSuccess, handleResponseFailure, currentInterface } = useAuthContext()

  useEffect(() => {
    if (profile === undefined) {
      return
    }

    const { contracts = [] } = profile

    setContarcts(contracts.map(item => ({ customerId: item.customer.id, num: item.num, createTs: item.createTs, filename: item.filename })) ?? [])
  }, [profile])

  const disabled = useMemo(() => (contracts.length === customers.length) ||
    (profile?.status && ![UserStatus.confirmed, UserStatus.active].includes(profile.status)) ||
    (currentInterface ? ![Interface.dispatcher, Interface.chief_dispatcher].includes(currentInterface) : true),
  [contracts, customers, profile?.status, currentInterface])

  useEffect(() => {
    customerDispatcherList().then(setCustomers)
  }, [])

  if (profile === undefined) {
    return (<></>)
  }

  const save = () => {
    if (!check(newContract)) {
      return
    }

    const { id } = profile

    const filtered = contracts.filter(item => item.customerId !== newContract.customerId)

    profileAllUpdate({ id, contracts: [...filtered, newContract] })
      .then(success => {
        if (success) {
          onSave()
          handleCancel()
          handleResponseSuccess('Данные сохранены')
        } else {
          handleResponseFailure('Данные не были сохранены')
        }
      })
  }

  const handleCancel = () => {
    setNewContract(undefined)
    setEdit(false)
    cleanErrors()
  }

  const edit = (id: Id) => {
    return () => {
      const found = contracts.find(item => item.customerId === id)

      if (found === undefined) {
        return
      }

      setEdit(true)
      setNewContract({ customerId: found.customerId, num: found.num, createTs: found.createTs, filename: found.filename })
    }
  }

  const customerFilter = () => {
    const excludeIds = contracts.map(item => item.customerId)

    return (item: Customer) => !isEdit ? !excludeIds.includes(item.id) : true
  }

  const updateCustomer = (customerId?: Id) => {
    if (!isId(customerId)) {
      return
    }

    setNewContract(prev => ({ ...prev, customerId }))
  }

  const updateNum = ({ target: { value: num } }: ChangeEvent<HTMLInputElement>) => {
    setNewContract(prev => ({ ...prev, num }))
  }

  const updateFile = (filename: string) => {
    setNewContract(prev => ({ ...prev, filename: filename !== '' ? filename : undefined }))
  }

  const updateDate = (createTs: number) => {
    setNewContract(prev => ({ ...prev, createTs }))
  }

  return (<>
    <InfoCard title='Договора' actions={
      <Button variant='contained' size='small' onClick={() => { setNewContract({}) }} disabled={disabled}>Добавить договор</Button>
    }>
      <>
      { profile.contracts && profile.contracts.length > 0
        ? <Stack direction='column' spacing={2}>
        { profile.contracts.map((item) => (<Stack direction='row' spacing={2} alignItems='center' key={item.num}>
          <Typography>Договор №{item.num} от {TsToFormatDate(item.createTs, 'dd.MM.yyyy')} с {item.customer.fullName}</Typography>
          <>
            { item.filename !== undefined && item.filename !== '' && <SquareButton size='small' variant='outlined' color='secondary' onClick={() => download(item.filename as string)} ><Visibility sx={{ color: '#EBEBEB' }} /></SquareButton> }
            { item.status === ProfileContractStatus.new && <Chip label="Не подписан" color="secondary" variant='outlined' size='small' /> }
            { item.status === ProfileContractStatus.signed && <Chip label="Подписан" color="primary" variant='outlined' size='small' />}
            <SquareButton size='small' variant='outlined' color='secondary' onClick={edit(item.customer.id)} >
              <Edit style={{ color: '#94A3B8', width: '16px' }} />
            </SquareButton>
          </>
        </Stack>))}
      </Stack>
        : <Stack minHeight='50px'>
        <None desc="Договора отсутствуют" />
      </Stack>}
      </>
    </InfoCard>

    <Modal
      maxWidth='sm'
      title='Добавить договор'
      onClose={handleCancel}
      open={newContract !== undefined}
      content={
        <Box minWidth='300px'>
          <Select
            name='customerId'
            label='Заказчик'
            placeholder='Выберите заказчика'
            options={customers.filter(customerFilter()).map(({ id, fullName }) => ({ value: id, name: fullName }))}
            value={newContract?.customerId}
            onChange={updateCustomer}
            errors={errors}
            disabled={isEdit}
          />
          <TextField
            name='num'
            label='Номер договора'
            placeholder='Введите номер договора'
            value={newContract?.num}
            errors={errors}
            onChange={updateNum}
          />
          <DatePicker
            name='createTs'
            label='Дата договора'
            value={newContract?.createTs}
            onChange={updateDate}
            errors={errors}
          />
          <Upload
            name='filename'
            label='Скан договора'
            value={newContract?.filename}
            onChange={updateFile}
            errors={errors}
          />
        </Box>
      }
      actions={<Stack direction='row' justifyContent='space-between' spacing={2} sx={{ width: '100%' }}>
        <Button variant='outlined' color='secondary' size='small' onClick={handleCancel}>Отменить</Button>
        <Button variant='contained' size='small' onClick={save}>Сохранить</Button>
      </Stack>}
    />
  </>)
}
