import { Address } from 'api/address'
import TsToFormatDate from 'util/TsToFormatDate'
import { Meta } from 'entity/Meta'

const address = new Meta<Omit<Address, 'id' | 'type' | 'bidIds' | 'routeIds' | 'pointType'>>(
  {
    status: { title: 'Статус' },
    address: { title: 'Адрес' },
    coordinates: { title: 'Координаты' },
    region: { title: 'Область' },
    city: { title: 'Населенный пункт' },
    name: { title: 'Название пункта' },
    weighingScaleLength: { title: 'Длина весов' },
    weighingScaleMaxLoad: { title: 'Грузоподъемность весов' },
    isPart: { title: 'В ГК "Астон"' },
    district: { title: 'Район' },
    street: { title: 'Улица' },
    building: { title: 'Дом' },
    externalId: { title: 'ID' },
    comment: { title: 'Комментарий' },
    consignorConsignee: { title: 'Организации' },
    archiveDate: { title: 'Дата архивации', format: (value) => typeof value === 'number' ? TsToFormatDate(value, 'dd.MM.yyyy') : `${value}` },
    archiveComment: { title: 'Комментарий при архивации' },
    addressOrig: { title: 'Адрес из КИС' }
  }
)

export default address
