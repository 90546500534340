import './styles.sass'
import { Box, Button, Checkbox, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMainRoutes } from 'routes'
import ChipStatus from 'ui/ChipStatus'
import DataTable, { Row } from 'ui/DataTable'
import NavigatePanel from 'ui/NavigatePanel'
import { actAllList as apiActList, ActAll as Act, ActStatus, actAllSign, checkChangedInDiadocStatus, Act as ActInterface, ActAllDiadocSyncState } from 'api/act'
import TsToFormatDate from 'util/TsToFormatDate'
import { MoreHoriz, Check, CheckCircleSharp, ClearSharp, ReportProblem } from '@mui/icons-material'
import { Id } from 'api/Id'
import ActionsMenu, { Action } from 'ui/ActionsMenu'
import { useAuthContext } from 'AuthContext'
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom'
import SignModal from 'pages/DispatcherActs/SignModal'
import SignSuccessModal from 'pages/DispatcherActs/SignSuccessModal'
import { isEnum } from 'util/isEnum'
import SquareButton from 'ui/SquareButton'
import addressName from 'util/addressName'
import { Interface } from 'api/Interface'
import { skipDiadoc } from 'ui/ActWizard'
import ActDispatcherSigningWizard from 'ui/ActDispatcherSigningWizard'
import ActDispatcherRejectionWizard from 'ui/ActDispatcherRejectionWizard'
import { CellDocs, CellStatus, CellSum, cellDocs, cellStatus, cellSum } from 'ui/ActRowComponents'
import Tooltip from 'ui/Tooltip'
import CheckedSuccessModal from './CheckedSuccessModal'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import metaAct from 'entity/Act'
import DiadocSync from 'ui/DiadocSync'
import { actStatusTab } from 'common/actStatusTab'

const statusMap = new Map<ActStatus, string>()
  .set(ActStatus.waitingSigning, 'Проверка')
  .set(ActStatus.dispatcherChecked, 'Ожидают подписания')
  .set(ActStatus.signedByCustomer, 'Подписаны')
  .set(ActStatus.refused, 'Отказано в подписании')
  .set(ActStatus.canceled, 'Аннулированы')
  .set(ActStatus.problem, 'Требуют внимания')

export const getChipsByStatus = (status: ActStatus) => {
  switch (status) {
    case ActStatus.waitingSigning:
      return <ChipStatus label='Ожидает проверки' />
    case ActStatus.dispatcherChecked:
      return <ChipStatus label='Ожидает подписания' color='yellow' />
    case ActStatus.signedByCustomer:
      return <ChipStatus label='Подписан' color='green' />
    case ActStatus.refused:
      return <ChipStatus label='Отказано в подписании' color='red' />
    case ActStatus.canceled:
      return <ChipStatus label='Аннулирован' color='red' />
    case ActStatus.changedInDiadoc:
      return <ChipStatus label='Изменен через Диадок' color='red' />
    case ActStatus.problem:
      return <ChipStatus label='Отклонен диспетчером' color='red' />
    default:
      return <></>
  }
}

const LIMIT = 10
const DEFAULT_STATUS = ActStatus.waitingSigning

export default function DispatcherActs () {
  const { currentInterface } = useAuthContext()
  const { links, routesMap } = useMainRoutes()
  const [searched, setSearched] = useState<(number|string)[]>()
  const [actList, setActList] = useState<Act[]>([])
  const [selectActList, setSelectActList] = useState<Id[]>([])
  const [listCount, setListCount] = useState<number>(0)
  const [listOffset, setListOffset] = useState<number>(0)
  const [signActs, setSignActs] = useState<Act[]>()
  const [signSuccessOpen, setSignSuccessOpen] = useState<boolean>(false)
  const [openSigningModal, setOpenSigningModal] = useState(false)
  const [selectedActs, setSelectedActs] = useState<Act[]>([])
  const [openRejectionModal, setOpenRejectionModal] = useState(false)
  const [rejectedActId, setRejectedActId] = useState<Id>()
  const [rejectedProblem, setRejectedProblem] = useState<boolean>()
  const [checkedActNum, setCheckedActNum] = useState<string>()
  const [diadocSyncState, setDiadocSyncState] = useState<ActAllDiadocSyncState>()

  const { status } = useParams()
  const filterStatus = isEnum(ActStatus)(status) ? status : DEFAULT_STATUS
  const navigate = useNavigate()
  const isChief = currentInterface === Interface.chief_dispatcher

  const { state } = useLocation()

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.DISPATCHER_DOCUMENTS_ACTS_PAGE),
    [links, routesMap]
  )

  const loadList = useCallback((offset?: number) => {
    apiActList({ filters: { status: [filterStatus] }, limit: LIMIT, offset }).then(result => {
      setActList(result.list)
      setListCount(result.count)
      setDiadocSyncState(result.syncState)
    })
  }, [filterStatus])

  // eslint-disable-next-line
  useEffect(() => loadList(listOffset), [listOffset])

  useEffect(() => {
    loadList()
    setSelectActList([])
  }, [filterStatus, loadList])

  useEffect(() => {
    if (!state) {
      return
    }

    if (!state.actNum) {
      return
    }

    setCheckedActNum(state.actNum)
  }, [state])

  const rows: Row[] = useMemo(() => {
    return actList.map(act => {
      const trips: { id: Id, name: string }[] = act.truckRuns
        .map(trucRun => ({
          id: trucRun.id,
          name: `${TsToFormatDate(trucRun.loadingTs, 'dd.MM.yyyy')}. ${addressName(act.bid.loadingAddress)} - ${addressName(trucRun.unloadingAddress ?? act.bid.unloadingAddress)}`
        }))
      const shortageSum = act.truckRuns.reduce((prev, cur) => cur.shortage.cost + prev, 0)
      const hasErrors = act.truckRuns.some(({ finalWeight, finalWeightCorrection, weightCorrection, weight, unloadingAddress }) =>
        (finalWeightCorrection !== undefined && finalWeight !== finalWeightCorrection) ||
        (weightCorrection !== undefined && weightCorrection !== weight) ||
        (unloadingAddress?.id !== act.unloadingAddress?.id)
      )

      return {
        id: act.id,
        num: act.num,
        dateTs: act.dateTs,
        carrier: act.organization.fullName,
        customer: act.bid.customer.fullName,
        trips,
        status: act.status,
        sum: act.totalSum,
        problemComment: act.problemComment,
        statuses: {
          status: act.status,
          registryStatus: act.registryStatus,
          shortageStatus: act.shortageStatus,
          agreementStatus: act.agreementStatus,
          invoiceStatus: act.invoiceStatus
        },
        docs: {
          filename: act.filename,
          registryFilename: act.registryFilename,
          shortageFilename: act.shortageFilename,
          agreementFilename: act.agreementFilename,
          invoiceFilename: act.invoiceFilename
        },
        amounts: {
          actSum: act.totalSum,
          shortageSum: act.shortageFilename !== undefined ? shortageSum : undefined,
          finalSum: act.invoiceFilename !== undefined ? act.totalSum - shortageSum : undefined,
          hasErrors
        },
        hasErrors,
        cssStyle: hasErrors ? { '&>td': { borderBottom: '1px solid #EE6A5F' } } : undefined
      }
    })
  }, [actList])

  const handleChangeCheckbox = (id: Id) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectActList(prev => event.target.checked ? [...prev, id] : prev.filter(item => item !== id))
    }
  }

  const handleSign = (ids: Id[]) => {
    const acts = actList.filter(({ id }) => ids.includes(id))
    if (skipDiadoc()) {
      setSignActs(acts)
    } else {
      setSelectedActs(acts)
      setOpenSigningModal(true)
    }
  }

  const handleReject = (id: Id, problem?: boolean) => {
    setRejectedActId(id)
    setOpenRejectionModal(true)
    setRejectedProblem(problem)
  }

  const tablePagination = (start: number, end: number) => {
    setListOffset(start)
    return rows.filter(row => searched === undefined ? true : searched.includes(row.id))
  }
  const getPack = useCallback((docs: {filename?:string, registryFilename?:string, shortageFilename?:string, agreementFilename?:string, invoiceFilename?:string}) => {
    return Object.entries(docs)
      .map(([key, filename]) => ({
        filename: filename || '',
        toName: filename ? metaAct.title(key as keyof ActInterface) : ''
      }))
      .filter(item => item.filename !== '')
  }, [])
  return (<>
    <SignModal
      acts={signActs}
      onCancel={() => {
        setSignActs(undefined)
        loadList()
      }}
      onSign={async (ids) => { actAllSign(ids) }}
      onFinish={() => {
        setSignActs(undefined)
        setSignSuccessOpen(true)
        loadList()
      }}
    />
    <SignSuccessModal
      open={signSuccessOpen}
      onClose={() => setSignSuccessOpen(false)}
    />
    <div className='dispatcherActs'>
      <NavigatePanel
        search={{
          rows: rows === undefined ? [] : rows,
          fields: [
            'num',
            'carrier',
            'customer',
            'sum'
          ],
          onChange: setSearched
        }}
        title='УПД'
        breadcrumbs={{
          items: breadcrumbsItems
        }}
        tabs={{
          items: [...statusMap].map(([value, label]) => actStatusTab({ label, value })),
          value: filterStatus,
          onChange: value => navigate(links.DISPATCHER_DOCUMENTS_ACTS_PAGE + (value === DEFAULT_STATUS ? '' : `/${value}`)),
          scrollable: true
        }}
        actions={<Stack direction='row' spacing={1} justifyContent='right'>
        { filterStatus === ActStatus.dispatcherChecked
          ? <>
            <Button size='small' variant='outlined'
              sx={{ borderRadius: '4px', textTransform: 'none' }}
              endIcon={<Check />}
              disabled={selectActList.length === 0}
              onClick={() => handleSign(selectActList)}
            >Подписать выбранные</Button>
            <Button size='small' variant='contained'
              sx={{ borderRadius: '4px', textTransform: 'none' }}
              endIcon={<CheckCircleSharp />}
              disabled={actList.length === 0}
              onClick={() => handleSign(rows.map(item => item.id as Id))}
            >Подписать все УПД</Button>
          </>
          : <></>
        }
        { isChief && <DiadocSync syncState={diadocSyncState} /> }
        </Stack>}
      />
      <div className='dispatcherActs__content'>
        <div className='dispatcherActs__content_table'>
          <DataTable
            verticalAlign='top'
            columns = { [
              {
                id: 'id',
                label: '',
                minWidth: 10,
                align: 'left',
                hide: filterStatus !== ActStatus.dispatcherChecked,
                format: value => <>
                  <Checkbox
                    checked={selectActList.includes(value as Id)}
                    onChange={handleChangeCheckbox(value as Id)}
                    sx={{ '&.Mui-checked': { color: '#0a5dff' } }}
                  />
                </>
              },
              {
                id: ['num', 'dateTs', 'hasErrors'],
                label: 'Номер',
                format: (num, dateTs, hasErrors) => <Typography
                  fontWeight={700}
                  fontSize='14px'
                  color={hasErrors ? '#EE6A5F' : undefined}
                >
                  № {`${num}`} от {TsToFormatDate(dateTs as number, 'dd.MM.yyyy')}
                </Typography>
              },
              {
                id: 'carrier',
                label: 'Перевозчик'
              },
              {
                id: 'customer',
                label: 'Заказчик'
              },
              {
                id: 'trips',
                label: 'Включенные рейсы',
                format: (value) => <>{(value as { id: Id, name: string }[]).map((item, idx) =>
                  <Typography fontSize='14px' key={idx} >
                    <NavLink style={{ color: '#111111' }} to={`${links.CARRIER_TRIPS_PAGE}/${item.id}`}>{item.name}</NavLink>
                  </Typography>)}</>
              },
              {
                id: 'docs',
                label: 'Документ',
                minWidth: 150,
                format: (value) => cellDocs(value as CellDocs)
              },
              {
                id: 'statuses',
                label: 'Статус',
                minWidth: 200,
                format: value => cellStatus(value as CellStatus<ActStatus>)(getChipsByStatus)
              },
              {
                id: 'amounts',
                label: 'Сумма',
                format: (amounts) => cellSum(amounts as CellSum)
              },
              {
                id: ['id', 'statuses', 'problemComment', 'docs', 'num', 'dateTs'],
                label: '',
                align: 'center',
                format: (vId, statuses, pc, docs: any, num, dateTs) => {
                  const id = vId as Id
                  const problemComment = pc as string | undefined
                  const problem = checkChangedInDiadocStatus(statuses as Act)

                  if (filterStatus === ActStatus.waitingSigning && isChief) {
                    return <SquareButton variant='contained' onClick={() => navigate(`${links.DISPATCHER_DOCUMENTS_ACT_PAGE}/${id}`)}><MoreHoriz /></SquareButton>
                  }

                  if (filterStatus === ActStatus.signedByCustomer) {
                    const pack = getPack(docs)
                    const date = TsToFormatDate(dateTs as number, 'dd.MM.yyyy').replaceAll('.', '_')
                    return <DownloadZipButton pack={pack} archiveName={slug(`Пакет документов к УПД ${num} от ${date}`)} iconOnly={true}/>
                  }

                  const actions: Action[] = []

                  if (filterStatus === ActStatus.dispatcherChecked && !problem) {
                    actions.push({
                      caption: 'Подписать',
                      onClick: () => handleSign([id]),
                      icon: <Check />
                    })
                  }

                  if (filterStatus === ActStatus.dispatcherChecked) {
                    actions.push({
                      caption: 'Отказать в подписании',
                      onClick: () => handleReject(id, problem),
                      icon: <ClearSharp />
                    })
                  }

                  return <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box sx={{ width: '17px', height: '17px', display: 'block' }}>
                      { problemComment && <Tooltip title={<Typography fontSize='14px' fontWeight={600} >{problemComment}</Typography>} arrow placement='right' view>
                        <ReportProblem sx={{ color: '#EE6A5F', fontSize: '17px' }}/>
                      </Tooltip> }
                    </Box>
                    { actions.length > 0 && <ActionsMenu key={`${id}`} actions = {actions} /> }
                  </Box>
                }
              }
            ] }
            rowsPage = {LIMIT}
            rows = {tablePagination}
            rowsCount = { listCount }
          />
        </div>
      </div>
    </div>

    <ActDispatcherSigningWizard
      open={openSigningModal}
      acts={selectedActs}
      onClose={() => {
        setOpenSigningModal(false)
        loadList(listOffset)
      }}
    />

    <ActDispatcherRejectionWizard
      open={openRejectionModal}
      actId={rejectedActId}
      problem={rejectedProblem}
      onClose={() => {
        setOpenRejectionModal(false)
        setRejectedProblem(undefined)
        loadList(listOffset)
      }}
    />

    <CheckedSuccessModal actNum={checkedActNum} onClose={() => setCheckedActNum(undefined)} />

  </>)
}
