import { MoreHoriz, Clear, Edit, Warning, Sync } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import {
  shippingTruckRunList,
  ShippingTruckRunList,
  ShippingTruckRunListParams,
  ShippingTruckRunStatus as Status,
  isArchiveStatus
} from 'api/shipping'
import { DAY } from 'constants/Time'
import { useCallback, useEffect, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import InfoCell, { InfoCellProps } from 'ui/InfoCell'
import SquareButton from 'ui/SquareButton'
import { personName } from 'util/personName'
import trailerName from 'util/trailerName'
import TsToFormatDate from 'util/TsToFormatDate'
import vehicleName from 'util/vehicleName'
import ChipTruckRunStatus from 'common/ChipTruckRunStatusForBid'
import './styles.sass'
import { SortOrder } from 'api/SortOrder'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import None from 'ui/None'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import TruckRunEditModel from 'ui/TruckRunEditModel'
import { toTon } from 'util/weight'
import { useAuthContext } from 'AuthContext'
import { Interface } from 'api/Interface'
import Tooltip from 'ui/Tooltip'
import ChipStatus from 'ui/ChipStatus'
import RedirectModal from 'pages/CarrierTrip/RedirectModal'
import { BidCargo, bidCargoList } from 'api/bid'
import KisRequestHistoryWizard from 'ui/KisRequestHistoryWizard'

interface ItemParams {
  data: ShippingTruckRunList
  onEdit?: (id: Id) => void
  onConfirmRedirect?: (data: ShippingTruckRunList) => void
  viewTruckRunId?: Id
}

const TruckRunItem = ({ data, onEdit, onConfirmRedirect, viewTruckRunId }: ItemParams) => {
  const { links } = useMainRoutes()
  const navigate = useNavigate()

  const highlight = viewTruckRunId === data.id
  const isRedirect = data.unloadingAddress !== undefined

  const cells: InfoCellProps[] = [
    { label: 'Номер рейса', value: data.num }
  ]

  data.forwarder !== undefined && cells.push({ label: 'Экспедитор', value: data.forwarder })

  cells.push(
    { label: 'Водитель', value: `${personName(data.driver)}` },
    { label: 'Транспортное средство', value: vehicleName(data.vehicle) },
    { label: 'Прицеп', value: trailerName(data.trailer) }
  )

  data.weight !== undefined && cells.push({ label: 'Вес погрузки', value: <>{toTon(data.weight)} тн</> })
  data.billNumber !== undefined && cells.push({ label: 'Номер ТрН/ТТН', value: data.billNumber })
  data.finalWeight !== undefined && cells.push({ label: 'Вес разгрузки', value: <>{toTon(data.finalWeight)} тн</> })

  const edit = onEdit && (!isArchiveStatus(data.status) || data.status === Status.archiveOutdated)
  const viewReqHistory = isArchiveStatus(data.status) || data.status === Status.way

  return (
    <InfoCard
      style={{
        marginBottom: '4px',
        background: highlight ? '#fff4e5' : undefined,
        borderColor: highlight ? '#ff9800' : isRedirect ? '#ffa800' : undefined
      }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Stack direction='row' gap={1}>
        { cells.map((item, idx) => <InfoCell {...item} key={idx} />) }
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Box>
          { data.status === Status.way && data.hasLongToWay && <Tooltip
              title={<Typography fontSize='14px' fontWeight={600} >Рейс находится в пути более двух суток</Typography>}
              arrow
              placement='right'
              view
            >
              <Warning sx={{ color: '#FFA800', display: 'block' }} />
            </Tooltip> }
          </Box>
          { viewReqHistory && data.kisRequestHistory && data.kisRequestHistory.length > 0 && <Box>
            <Tooltip
              title={<Stack direction='column' gap={0.5}>
                <Typography fontSize='14px' fontWeight={600} >История запросов КИС</Typography>
                {data.kisRequestHistory.sort((a, b) => b.requestTs - a.requestTs).slice(0, 5).map(item => <KisRequestHistoryWizard data={item} />)}
              </Stack>}
              arrow
              placement='top'
              view
            >
              <Sync color='secondary' sx={{ display: 'block' }} />
            </Tooltip>
          </Box> }
          { isRedirect && <Box onClick={() => onConfirmRedirect && !data.consignee && onConfirmRedirect(data)} sx={{ cursor: 'pointer' }}>
            <ChipStatus color='yellowBorder' label='Переадресация' style={{ opacity: data.consignee ? 0.7 : 1 }}/>
          </Box>}
          {ChipTruckRunStatus(data.status)}
          { edit && <SquareButton
            size='small'
            variant='outlined'
            color='secondary'
            onClick={() => onEdit(data.id)} >
            <Edit style={{ color: '#94A3B8', width: '16px' }}
          />
          </SquareButton> }
          <SquareButton
            variant='contained'
            color='primary'
            onClick={() => navigate(`${links.CARRIER_TRIPS_PAGE}/${data.id}`)}
          >
            <MoreHoriz sx={{ color: '#ffffff', width: '16px' }} />
          </SquareButton>
        </Stack>
      </Stack>
    </InfoCard>
  )
}

const TruckRunItems = ({ list, onEdit, onConfirmRedirect, viewTruckRunId }: {
  list: [number, Map<string, ShippingTruckRunList[]>][],
  onEdit?: (id: Id) => void,
  onConfirmRedirect?: (data: ShippingTruckRunList) => void,
  viewTruckRunId?: Id
}) => {
  return (<>
    { list.map(([day, list]) => <Box key={day} sx={{ marginBottom: '1em' }}>
      <Typography sx={{
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '-0.01em',
        color: '#B2B2B2'
      }}>
        {/* {day === today ? 'Сегодня' : day === today + DAY ? 'Завтра' : TsToFormatDate(day, 'd MMMM')} */}
        {TsToFormatDate(day, 'd MMMM')}
      </Typography>
      {[...list].map(([address, list]) => <Box key={address}>
        <Typography sx={{
          fontWeight: '600',
          fontSize: '15px',
          lineHeight: '18px',
          letterSpacing: '-0.01em',
          color: '#111111',
          pt: 1
        }}>
          {address}
        </Typography>
        <Stack direction='column'>
        {list.map((item, idx) => <TruckRunItem
          data={item}
          key={idx}
          onEdit={onEdit}
          onConfirmRedirect={onConfirmRedirect}
          viewTruckRunId={viewTruckRunId}
        />)}
        </Stack>
      </Box>)}
    </Box>) }
  </>)
}
interface Params {
  bidId?: string
  onlyRefused?: boolean
  onlyRedirected?: boolean
  onChange: () => void
  viewTruckRunId?: Id
}

export default function TruckRunsList ({ bidId, onChange, onlyRefused = false, onlyRedirected = false, viewTruckRunId }: Params) {
  const [list, setList] = useState<Map<number, Map<string, ShippingTruckRunList[]>>>()
  const [dateRange, setDateRange] = useState<RangeDate>()
  const [openRedirectModal, setOpenRedirectModal] = useState<ShippingTruckRunList>()
  const [editId, setEditId] = useState<Id>()
  const { currentInterface } = useAuthContext()
  const [cargoList, setCargoList] = useState<BidCargo[]>([])

  const isChief = currentInterface === Interface.chief_dispatcher

  useEffect(() => { bidCargoList().then(setCargoList) }, [])

  const initTruckRuns = useCallback((dateRange?: RangeDate) => {
    if (!isId(bidId)) {
      return
    }

    const filters: ShippingTruckRunListParams['filters'] = { bidId }
    filters.status = !onlyRefused
      ? [
          Status.confirmed,
          Status.way,
          Status.arrived,
          Status.completed,
          Status.archiveAct,
          Status.archiveOutdated,
          Status.archiveProblem
        ]
      : [Status.archiveRefuse]

    if (dateRange !== undefined) {
      filters.loadingTsFrom = dateRange.startTs
      filters.loadingTsTo = dateRange.endTs
    }

    shippingTruckRunList({ filters, sort: { loadingTs: SortOrder.asc } }).then((result) => {
      const byDay = new Map<number, Map<string, ShippingTruckRunList[]>>()

      for (const item of result) {
        const { loadingTs, unloadingAddress } = item

        if (onlyRedirected && !unloadingAddress) {
          continue
        }

        const day = loadingTs - (loadingTs % DAY)
        const carrier = item.carrierName
        const dayItems = byDay.get(day) ?? new Map<string, ShippingTruckRunList[]>()
        const carrierItems = dayItems.get(carrier) ?? []
        carrierItems.push(item)
        dayItems.set(carrier, carrierItems)
        byDay.set(day, dayItems)
      }

      setList(byDay)
    })
  }, [bidId, onlyRefused, onlyRedirected])

  useEffect(() => { setDateRange(undefined) }, [onlyRefused, onlyRedirected])

  useEffect(() => {
    initTruckRuns(dateRange)
  }, [initTruckRuns, dateRange])

  if (list === undefined) {
    return <></>
  }

  return (<>
    <div className='dispatcherBids__truckRunsList'>
      <Box
        display='flex'
        width='100%'
        p='0.4em 1.5em 0'
        sx={{
          background: '#ffffff',
          borderBottom: '1px solid #E2E8F0'
        }}
      >
        <Stack direction='row' alignItems='center' >
          <DatePicker
            placeholder='Фильтр по дате'
            range={dateRange}
            onChange={(startTs, endTs) => {
              if (startTs !== undefined && endTs !== undefined) {
                setDateRange(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
              }
            }}
            rangeDate
          />
          { dateRange && <SquareButton
            widthHeight='40px'
            variant='contained'
            color='inherit'
            onClick={() => setDateRange(undefined)}
            sx={{ mb: '0.5em', ml: '5px' }}
          ><Clear sx={{ color: '#ffffff' }} /></SquareButton>}
        </Stack>
      </Box>
      <div className='dispatcherBids__truckRunsList_content'>
        { list.size > 0
          ? <div className='dispatcherBids__truckRunsList_content_items'>
            { <TruckRunItems
              list={[...list]}
              onEdit={isChief ? setEditId : undefined}
              onConfirmRedirect={setOpenRedirectModal}
              viewTruckRunId={viewTruckRunId}
            />}
          </div>
          : <None desc='В настоящее время отсутствуют рейсы заданным параметрам сортировки.' m='1.5em' />}
      </div>
    </div>
    <TruckRunEditModel
      cargoList={cargoList}
      id={editId}
      onClose={() => setEditId(undefined)}
      onSave={() => {
        initTruckRuns(dateRange)
        onChange()
      }}
    />
    <RedirectModal
      data={openRedirectModal}
      onClose={() => setOpenRedirectModal(undefined)}
      onDone={() => {
        setOpenRedirectModal(undefined)
        initTruckRuns(dateRange)
      }}
    />
  </>)
}
