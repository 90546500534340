import './styles.sass'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, Stack, Alert } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { CarrierContractWizardType } from '.'
import { profileContractCreate } from 'api/profile'
import SquareButton from 'ui/SquareButton'
import download from 'util/download'
import { Visibility } from '@mui/icons-material'

const DOCUMENT_CREATE_ERROR = 'При создании договора, произошла ошибка.'

export default function Creating ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState<string>()

  const d = data as CarrierContractWizardType

  useEffect(() => {
    if (isActive) {
      profileContractCreate(d.customer.id)
        .then(result => {
          const { success, filename, conflicts } = result
          if (success) {
            d.onContract(filename)
            d.onCreated(true)
            return
          }

          const { signedContract, documentCreateError } = conflicts ?? {}

          if (signedContract ?? documentCreateError) {
            setError(DOCUMENT_CREATE_ERROR)
          }
        })
        .catch(() => setError(DOCUMENT_CREATE_ERROR))
    }
  }, [isActive, d, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      { error === undefined
        ? d.contract === undefined
          ? <Stack display='flex' flexDirection='column' alignItems='center'>
              <CircularProgress />
            <Typography>Создаем договор</Typography>
          </Stack>
          : <Stack direction='row' spacing={1} alignItems='center'>
              <Typography>Просмотреть договор</Typography>
              <SquareButton variant='outlined' color='secondary' onClick={() => download(d.contract)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
          </Stack>
        : <>
          <Alert severity="error">{error}</Alert>
        </>
      }
    </Box>
  </>)
}
