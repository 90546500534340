import './styles.sass'
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Act } from 'api/act'
import TsToFormatDate from 'util/TsToFormatDate'
import SquareButton from 'ui/SquareButton'
import { Row } from 'ui/DataTable'
import formatWeight from 'util/formatWeight'
import download from 'util/download'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useAuthContext } from 'AuthContext'
import { TypeTaxation } from 'api/organization'

export interface Props {
  acts?: Act[]
}

export default function ActPreview ({ acts = [] }: Props) {
  const { profile } = useAuthContext()

  const actRows: Row[] = useMemo(() => {
    const fee = profile?.organization?.typeTaxation === TypeTaxation.vat ? 20 : 0

    return acts.map((item) => {
      const truckRuns = item.truckRuns.map(truckRun => ({
        id: truckRun.id,
        num: truckRun.num,
        loadingTs: truckRun.loadingTs,
        weight: truckRun.weight ?? 0,
        finalWeight: truckRun.finalWeight ?? 0,
        status: truckRun.status,
        shortageCost: truckRun.shortage.cost,
        shortageWeight: truckRun.shortage.weight,
        cost: truckRun.cost === undefined ? 0 : (truckRun.cost - truckRun.shortage.cost) * (100 + fee) / 100
      }))

      return {
        id: item.id,
        filename: item.filename,
        registryFilename: item.registryFilename,
        shortageFilename: item.shortageFilename,
        agreementFilename: item.agreementFilename,
        invoiceFilename: item.invoiceFilename,
        bidDesc: item.bid.num,
        customer: item.bid.customer.fullName,
        // totalCost: truckRuns.reduce((result, { cost }) => result + cost, 0),
        truckRuns: truckRuns as Row[],
        totalFinalWeight: truckRuns.reduce((result, { finalWeight }) => result + finalWeight, 0),
        totalShortageWeight: truckRuns.reduce((result, { shortageWeight }) => result + shortageWeight, 0)
      }
    })
  }, [acts, profile])

  return (
    <Box sx={{ minWidth: '550px', minHeight: '250px' }}>
      <TableContainer component={Paper}>
        <Table aria-label='caption table' className='modal_act_step_1'>
          {actRows.map((row) => (<TableBody key={row.id}>
            <TableRow key='head1'>
              <TableCell colSpan={5} sx={{ border: 'none' }}>
                <Stack gap={1}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <SquareButton variant='outlined' color='primary' onClick={() => download(`${row.filename}`)}><VisibilityIcon sx={{ width: '16px' }}/></SquareButton>
                    <Typography gutterBottom fontWeight={650} fontSize='14px'>УПД по перевозке {row.bidDesc}</Typography>
                    <Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>{row.customer}</Typography>
                  </Stack>
                  {row.registryFilename && <Stack direction='row' spacing={2} alignItems='center'>
                    <SquareButton variant='outlined' color='primary' onClick={() => download(`${row.registryFilename}`)}><VisibilityIcon sx={{ width: '16px' }}/></SquareButton>
                    <Typography gutterBottom fontWeight={650} fontSize='14px'>Реестр оказанных услуг</Typography>
                  </Stack>}
                  { row.invoiceFilename && <Stack direction='row' spacing={2} alignItems='center'>
                    <SquareButton variant='outlined' color='primary' onClick={() => download(`${row.invoiceFilename}`)}><VisibilityIcon sx={{ width: '16px' }}/></SquareButton>
                    <Typography gutterBottom fontWeight={650} fontSize='14px'>Счет</Typography>
                  </Stack>}
                  {row.shortageFilename && <Stack direction='row' spacing={2} alignItems='center'>
                    <SquareButton variant='outlined' color='primary' onClick={() => download(`${row.shortageFilename}`)}><VisibilityIcon sx={{ width: '16px' }}/></SquareButton>
                    <Typography gutterBottom fontWeight={650} fontSize='14px'>Отчёт по недостачам</Typography>
                  </Stack>}
                  {row.agreementFilename && <Stack direction='row' spacing={2} alignItems='center'>
                    <SquareButton variant='outlined' color='primary' onClick={() => download(`${row.agreementFilename}`)}><VisibilityIcon sx={{ width: '16px' }}/></SquareButton>
                    <Typography gutterBottom fontWeight={650} fontSize='14px'>Соглашение</Typography>
                  </Stack>}
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow key='head2'>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Номер рейса</Typography></TableCell>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Вес погрузки</Typography></TableCell>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Вес разгрузки</Typography></TableCell>
              <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Недостача</Typography></TableCell>
              {/* <TableCell><Typography fontWeight={400} fontSize='14px' color='#B2B2B2'>Сумма</Typography></TableCell> */}
            </TableRow>
            { row.truckRuns.map((item: Row) => (<TableRow key={item.id}>
              <TableCell>{item.num} от {TsToFormatDate(item.loadingTs, 'dd.MM.yyyy')}</TableCell>
              <TableCell>{formatWeight(item.weight)}</TableCell>
              <TableCell>{formatWeight(item.finalWeight)}</TableCell>
              <TableCell>{formatWeight(item.shortageWeight)}</TableCell>
              {/* <TableCell>{rub(item.cost)}</TableCell> */}
            </TableRow>)) }
            {/* <TableRow key='total'>
              <TableCell colSpan={3} sx={{ borderBottom: 'none' }}><Box sx={{ display: 'flex', justifyContent: 'right', mr: 1 }}>Итого</Box></TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>{rub(row.totalCost)}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell colSpan={3} sx={{ borderBottom: 'none' }}><Box sx={{ display: 'flex', justifyContent: 'right', mr: 1 }}>Итого вывезено тон</Box></TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>{formatWeight(row.totalFinalWeight)}</TableCell>
            </TableRow>
            { row.totalShortageWeight > 0 && <TableRow>
              <TableCell colSpan={3} sx={{ borderBottom: 'none' }}><Box sx={{ display: 'flex', justifyContent: 'right', mr: 1 }}>Общая недостача</Box></TableCell>
              <TableCell sx={{ borderBottom: 'none' }}>{formatWeight(row.totalShortageWeight)}</TableCell>
            </TableRow>}
          </TableBody>))}
        </Table>
      </TableContainer>
    </Box>
  )
}
