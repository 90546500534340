import './styles.sass'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import Select from 'ui/Select'
import { Organization, TypeLegalPerson } from 'api/organization'
import { metaOrganization, TaxationNames } from 'entity/Organization'
import { SectionParams } from '../index'
import {
  ORGANIZATION_INN_MASK,
  ORGANIZATION_INN_ST_MASK,
  cleanMaskNum,
  ORGANIZATION_OKVAD_MASK,
  formatToOkved, ORGANIZATION_KPP_MASK
} from 'common/Mask/Organization'
import Upload from 'ui/Upload'
import validateBik from 'validation/validateBik'
import { CBRBank, cbrFindBank } from 'api/cbr'
import checkCheckingRusAccount from 'util/checkCheckingRusAccount'
import { okvedList } from 'api/okved'
import { enableNewOkved } from '../../PostRegistration/OrganizationData'
import OrganisationParticipants, { ParticipantsUpdate } from 'ui/OrganisationParticipants'

type Data = Organization
interface Store {
  checkBik: boolean
  checkAccount: boolean
  bankData?: CBRBank
  disabledBankField: boolean
  participants?: ParticipantsUpdate[]
  setParticipants: React.Dispatch<React.SetStateAction<ParticipantsUpdate[] | undefined>>
  setDisabledBankField: React.Dispatch<React.SetStateAction<boolean>>
  setCheckBik: React.Dispatch<React.SetStateAction<boolean>>
  setCheckAccount: React.Dispatch<React.SetStateAction<boolean>>
  setBankData: React.Dispatch<React.SetStateAction<CBRBank | undefined>>
}

export default function OrganizationCarrierProfile ({
  data, setData, disabled, errors, onBlur, checkBik, setCheckBik,
  checkAccount, setCheckAccount, bankData, setBankData,
  disabledBankField, setDisabledBankField, participants, setParticipants
}: SectionParams<Data> & Store) {
  const [searchBank, setSearchBank] = useState(false)
  const [okvedOptionList, setOkvedOptionList] = useState<{name: string, value: string, isActive: boolean}[]>([])

  const updateString = (key: keyof Data) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setData((data) => ({ ...data, [key]: value === '' ? undefined : value }))

  const updateOkved = (key: keyof Data) =>
    (value: string[]) =>
      setData((data) => ({ ...data, [key]: value.length ? value : undefined }))

  const updateUpload = (key: keyof Data) =>
    (filename?: string) =>
      setData((data) => ({ ...data, [key]: filename || undefined }))

  const isLtd = useMemo(() =>
    data.typeLegalPerson === TypeLegalPerson.organization
  , [data.typeLegalPerson])

  const metaOrg = useMemo(() =>
    metaOrganization(data.typeLegalPerson)
  , [data.typeLegalPerson])

  useEffect(() => {
    if (!data.bik) {
      return
    }

    const checkValue = cleanMaskNum(data.bik)
    if (!validateBik(checkValue)) {
      return
    }

    setSearchBank(true)
    cbrFindBank(data.bik)
      .then(response => {
        const { success, result, conflicts } = response

        setDisabledBankField(false)
        if (!success && conflicts?.bikNotFound) {
          setBankData(undefined)
          setCheckBik(false)
          return
        }

        setCheckBik(true)
        setBankData(result ?? undefined)
      })
      .finally(() => setSearchBank(false))
  }, [data.bik, setCheckBik, setBankData, setDisabledBankField])

  useEffect(() => {
    if (bankData === undefined) {
      return
    }

    if (!data?.correspondentAccountNumber?.match(/\d{20}/)) {
      return
    }

    if (bankData.accounts.find(item => item.correspondentAccountNumber === data.correspondentAccountNumber)) {
      setCheckAccount(true)
      return
    }

    setCheckAccount(false)
  }, [bankData, data.correspondentAccountNumber, setCheckAccount])

  useEffect(() => {
    if (isLtd) {
      okvedList()
        .then((list) => {
          const result = list.map((item) => ({
            name: `${formatToOkved(item.code)} ${item.description}`,
            value: formatToOkved(item.code),
            isActive: item.isActive
          }))
          setOkvedOptionList(result)
        })
    }
  }, [isLtd])

  return (
    <Box position='relative'>
      { searchBank && <Box position='absolute' sx={{ backgroundColor: 'rgb(255 255 255 / .7)' }} width='100%' height='100%' zIndex={1000}>
        <Stack width='100%' height='100%' alignItems='center' justifyContent='center' direction='column' spacing={2} >
          <CircularProgress />
          <Typography>Поиск данных организации, подождите...</Typography>
        </Stack>
      </Box> }
    <InfoCard title='Общая информация'>
      <Grid container spacing={2}>
        <Grid item sm={6} md={3}>
          <TextField
            name='inn'
            label={metaOrg.title('inn')}
            value={data.inn}
            onChange={updateString('inn')}
            placeholder={isLtd ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX'}
            maskParams={{
              mask: isLtd ? ORGANIZATION_INN_MASK : ORGANIZATION_INN_ST_MASK
            }}
            disabled={true}
            errors={errors}
            onBlur={onBlur && onBlur('inn')}
          />
        </Grid>
        { isLtd && <Grid item sm={6} md={3}>
          <TextField
            name='kpp'
            label={metaOrg.title('kpp')}
            value={data.kpp}
            onChange={updateString('kpp')}
            placeholder='XXXXXXXXX'
            maskParams={{
              mask: ORGANIZATION_KPP_MASK
            }}
            disabled={true}
            errors={errors}
            onBlur={onBlur && onBlur('kpp')}
          />
        </Grid> }
        <Grid item sm={6} md={3}>
          <TextField
            name='shortName'
            label={metaOrg.title('shortName')}
            value={data.shortName}
            onChange={updateString('shortName')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('shortName')}
          />
        </Grid>
        <Grid item sm={6} md={isLtd ? 3 : 6}>
          <TextField
            name='fullName'
            label={metaOrg.title('fullName')}
            value={data.fullName}
            onChange={updateString('fullName')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('fullName')}
          />
        </Grid>
        {!isLtd &&
          <>
            <Grid item sm={6} md={4}>
              <TextField
                name='ogrnip'
                label={metaOrg.title('ogrnip')}
                value={data.ogrnip}
                onChange={updateString('ogrnip')}
                placeholder='X-XX-XX-XXXXXXXXX-X'
                maskParams={{
                  mask: metaOrg.mask('ogrnip')
                }}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('ogrnip')}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <TextField
                name='okpo'
                label={metaOrg.title('okpo')}
                value={data.okpo}
                onChange={updateString('okpo')}
                placeholder='XXXXXXXX'
                maskParams={{
                  mask: metaOrg.mask('okpo')
                }}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('okpo')}
              />
            </Grid>
          </>
        }

        { isLtd &&
          <>
            { enableNewOkved() && <Grid item xs={12}>
              <Select
                name='okveds'
                label={metaOrg.title('okveds')}
                value={data.okveds?.map((v:string) => formatToOkved(v))}
                onChange={updateOkved('okveds')}
                placeholder='XX.XX.XX'
                disabled={disabled}
                errors={errors}
                options={okvedOptionList}
                multiple={true}
                errorMessage={!data.okveds?.length ? 'Поле обязательно к заполнению' : ''}
                inputScroll={true}
              />
            </Grid> }
            { !enableNewOkved() && <Grid item xs={6} md={4}>
              <TextField
                name='okved'
                label={metaOrg.title('okved')}
                value={data.okved}
                onChange={updateString('okved')}
                placeholder='XX.XX.XX'
                maskParams={{
                  mask: ORGANIZATION_OKVAD_MASK
                }}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('okved')}
                />
            </Grid> }
            <Grid item xs={6} md={4}>
              <TextField
                name='ogrn'
                label={metaOrg.title('ogrn')}
                value={data.ogrn}
                onChange={updateString('ogrn')}
                placeholder='X-XX-XX-XXXXXXX-X'
                maskParams={{
                  mask: metaOrg.mask('ogrn')
                }}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('ogrn')}
              />
            </Grid>
          </>
        }

        <Grid item sm={6} md={4}>
          <div className='TextField'>
            <Select
              label={metaOrg.title('typeTaxation')}
              options={Array.from(TaxationNames, ([value, name]) => ({ value, name }))}
              value={data.typeTaxation}
              onChange={(typeTaxation) => setData((data) => ({ ...data, typeTaxation }))}
              disableClearable={true}
              disabled={disabled}
              errors={errors}
            />
          </div>
        </Grid>

        <Grid item sm={6} md={4}>
          <TextField
            name='bik'
            label={metaOrg.title('bik')}
            value={data.bik}
            onChange={updateString('bik')}
            placeholder='XXXXXXXXX'
            maskParams={{
              mask: metaOrg.mask('bik')
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('bik')}
            errorMessage={!checkBik ? 'Указанный БИК не найден в справочнике ЦБР' : undefined}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <TextField
            name='checkingAccountNumber'
            label={metaOrg.title('checkingAccountNumber')}
            value={data.checkingAccountNumber}
            onChange={updateString('checkingAccountNumber')}
            placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
            maskParams={{
              mask: metaOrg.mask('checkingAccountNumber')
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('checkingAccountNumber')}
          />
        </Grid>
        <Grid item sm={6} md={4}>
          <TextField
            name='correspondentAccountNumber'
            label={metaOrg.title('correspondentAccountNumber')}
            value={data.correspondentAccountNumber}
            onChange={updateString('correspondentAccountNumber')}
            placeholder='XXXXXXXXXXXXXXXXXXXX'
            maskParams={{
              mask: metaOrg.mask('correspondentAccountNumber')
            }}
            disabled={disabled || disabledBankField}
            errors={errors}
            onBlur={onBlur && onBlur('correspondentAccountNumber')}
            errorMessage={!checkCheckingRusAccount(data.correspondentAccountNumber) ? 'Некорректный к/с' : !checkAccount ? 'Номер к/с не найден для указанного БИК' : undefined}
          />
        </Grid>

        { isLtd &&
          <>
            <Grid item sm={6} md={4}>
              <TextField
                name='organizationHeadName'
                label={metaOrg.title('organizationHeadName')}
                value={data.organizationHeadName}
                onChange={updateString('organizationHeadName')}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('organizationHeadName')}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <TextField
                name='position'
                label={metaOrg.title('position')}
                value={data.position}
                onChange={updateString('position')}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('position')}
              />
            </Grid>
            <Grid item sm={6} md={4}>
              <TextField
                name='appointmenDocumentTitle'
                label={metaOrg.title('appointmenDocumentTitle')}
                value={data.appointmenDocumentTitle}
                onChange={updateString('appointmenDocumentTitle')}
                disabled={disabled}
                errors={errors}
                onBlur={onBlur && onBlur('appointmenDocumentTitle')}
              />
            </Grid>
          </>
        }

        <Grid item md={12}>
          <TextField
            name='bankName'
            label={metaOrg.title('bankName')}
            value={data.bankName}
            onChange={updateString('bankName')}
            disabled={disabled || disabledBankField}
            errors={errors}
            onBlur={onBlur && onBlur('bankName')}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            name='legalAddress'
            label={metaOrg.title('legalAddress')}
            value={data.legalAddress}
            onChange={updateString('legalAddress')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('legalAddress')}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            name='mailingAddress'
            label={metaOrg.title('mailingAddress')}
            value={data.mailingAddress}
            onChange={updateString('mailingAddress')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('mailingAddress')}
          />
        </Grid>
        <Grid item md={12}>
          <Upload
            label='Справка с реквизитами, предоставленная банком'
            name="scanRequisites"
            value={data.scanRequisites}
            onChange={updateUpload('scanRequisites')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
          />
        </Grid>
        <Grid item md={12}>
        { isLtd && <OrganisationParticipants rows={participants} setRows={setParticipants} disabled={disabled} /> }
        </Grid>
      </Grid>
    </InfoCard>
    </Box>
  )
}
